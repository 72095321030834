import { StyledFlexbox } from "components/styles/SideBySide.styled";
import { styled } from "styled-components";

const StyledFlagHeading = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;

  position: relative;
  width: 100%;
  grid-area: 1 / 4 / 2 / 5;

  & > div {
    background-color: #12121d;
    min-height: 150px;
    padding: 30px 0;
    width: max(60%, 500px);
    border-radius: 10px;

    & > h1 {
      text-align: center;
    }

    & > p {
      text-align: center;
      width: 80%;
    }
  }

  @media screen and (max-width: 1370px) {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const FlagHeading = ({ heading, children: text, ...props }) => {
  return (
    <StyledFlagHeading {...props}>
      <StyledFlexbox $align="center" $justify="center">
        <h1>{heading}</h1>
        {text && <p>{text}</p>}
      </StyledFlexbox>
    </StyledFlagHeading>
  );
};

export default FlagHeading;
