import { useRef, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FormPage from "components/canvas/FormPage";

import { AccountContext } from "components/contexts/AccountContext";

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledGroupWrapper,
} from "_components/features/form/styles/FormStructs.styled";

import FormErrorMessage from "_components/features/form/elements/FormErrorMessage";
import FormItem from "_components/features/form/FormItem";
import FormCta from "_components/features/form/FormCta";

import { loginUser } from "js/features/session/userSession";
import { getErrorMessage } from "js/data/formErrorMessages";

function Login() {
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [errorMessages, setErrorMessages] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);
    loginUser(formRef, navigate)
      .then(() => {
        setLoggedIn(true);
      })
      .catch((error) => {
        setErrorMessages([getErrorMessage(error, navigate, pathname)]);
      });
  };

  return (
    <>
      <FormPage>
        <StyledFormContainer
          $shadowed
          ref={formRef}
          onSubmit={(e) => handleSubmit(e)}
        >
          <StyledFormTitle $bottom="10px">Login</StyledFormTitle>
          <FormItem
            $label="Username / Email"
            placeholder="username / example@gmail.com"
            name="username"
            required
            autoFocus
          />
          <FormItem
            $label="Password"
            placeholder="password"
            type="password"
            name="password"
            required
          />

          {errorMessages.length > 0 && (
            <StyledGroupWrapper>
              {errorMessages.map((message, index) => (
                <FormErrorMessage message={message} key={index} />
              ))}
            </StyledGroupWrapper>
          )}

          <FormCta>Login</FormCta>
        </StyledFormContainer>
      </FormPage>
    </>
  );
}

export default Login;
