export const hexToRgba = (variable, alpha) => {
  // Either send a hex '#FFF' or a variable '--action-primary'
  const hex =
    variable.slice(0, 1) === "#"
      ? variable
      : getComputedStyle(document.documentElement)
          .getPropertyValue(variable)
          .trim();

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // Don't want to use alpha, instead wan the rgb values to manipluate
  if (!alpha) {
    return { r, g, b };
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
