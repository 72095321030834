import { useContext } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import { StyledXStrip } from "_components/themes/brand/XStrip.styled";

import { StyledFlexbox, StyledSbs } from "components/styles/SideBySide.styled";

import {
  StyledLineInfoBlock,
  StyledLineInfoBlockP,
} from "_components/molecules/containers/common/styles/StyledInfoBlock.styled";

import { SquareCta } from "components/CtaElements";
import LoneCtaSection from "components/sections/common/LoneCtaSection";

import { AccountContext } from "components/contexts/AccountContext";

const ForNonRbx = () => {
  const [loggedIn] = useContext(AccountContext);

  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/barrumble-gfx.jpg">
        <h1>For Non-Roblox Youtubers</h1>
        <p>
          All information regarding the difference between joining as a
          non-Roblox Youtuber as opposed to a primarily Roblox Youtuber
        </p>
      </TopHeadingBanner>
      <StyledStandardSpacer $paddingTop="200px" />

      <StyledXStrip $pull={3} style={{ padding: "100px 0" }}>
        <StyledSbs $justify="space-evenly">
          <StyledLineInfoBlock
            $colBack="var(--main-background)"
            $maxWidth="min(700px, 80%)"
            $addedHeight="50px"
          >
            <h1 style={{ margin: "0 0 30px 0" }}>Overview</h1>
            <h2 style={{ margin: "0" }}>The Explanation</h2>
            <StyledLineInfoBlockP>
              Most of our site may seem specifically catered to Roblox YouTubers
              only, but that is not the case. All YouTubers are welcome to join
              and encouraged to join for the same reasons as the Roblox
              YouTubers. The reason we mention "Roblox Youtubers" specifically,
              is because the extra rewards we offer are primarily sourced from
              "roblox.com" (which Roblox YouTubers will be more familiar with
              and find easier to understand).
            </StyledLineInfoBlockP>
            <StyledLineInfoBlockP>
              That being said, if you simply read through our site's concise and
              helpful information, you will quickly understand how Roblox works.
              You will understand how we manipulate Roblox's system use our
              assets effectively in order to bring you the rewards and benefits
              which are listed on our site. We bundle these rewards and
              benefits, for free, on top of the already included offer of a
              skilled, co-ordinated production team who provide editing and
              thumbnail services of quality and quantity.
            </StyledLineInfoBlockP>

            <h2 style={{ margin: "0" }}>The Difference</h2>
            <StyledLineInfoBlockP>
              The only direct difference between joining as a "Non-Roblox
              Youtuber" as apposed to a "Roblox YouTuber" is: You will not
              receive free advertising of your brand from us.
            </StyledLineInfoBlockP>
            <StyledLineInfoBlockP>
              This is because our marketing strategies for advertising our
              Partner's brands is by promoting them in Roblox communities of
              Roblox games. Both in-game and out-of-game. These games we either
              own, manage or are associated with. It then means, if we were to
              advertise your "non-Roblox brand" to a "Roblox community", it
              would be considerably less effective.
            </StyledLineInfoBlockP>

            <h2 style={{ margin: "0" }}>The Good News</h2>
            <StyledLineInfoBlockP>
              You still receive everything your pay for as Roblox YouTubers pay
              for. Remember, you are paying for a production team for your
              channel. The advertising, game profits, collaborations, and all
              other benefits we offer you in our company are all{" "}
              <b>free added bonus'</b> which you <b>do not pay for</b>. So the
              "good news", is that you don't lose any value for your money. The
              "free advertising benefit" is only a small part of the countless{" "}
              <b>free extra bonus'</b> we offer. So join us for free and find
              out if you like it!
            </StyledLineInfoBlockP>
          </StyledLineInfoBlock>

          <StyledFlexbox
            $gap="20px"
            style={{
              padding: "20px",
              border: "dotted 2px var(--sub-info-alt)",
              maxWidth: "350px",
            }}
          >
            <h2>View Plans</h2>
            <p>
              View the selectable plans for Non-Roblox YouTubers and Roblox
              YouTubers
            </p>
            <SquareCta to="/pricing?rbx=no">Let's Go</SquareCta>
          </StyledFlexbox>
        </StyledSbs>
      </StyledXStrip>
      <StyledStandardSpacer $paddingTop="400px" />

      {loggedIn ? (
        <LoneCtaSection to="/profile?tab=payment">
          Purchase Services
        </LoneCtaSection>
      ) : (
        <LoneCtaSection to="/join">Get Started</LoneCtaSection>
      )}
    </StyledStandardEndSection>
  );
};

export default ForNonRbx;
