import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import FormPage from "components/canvas/FormPage";

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledFormOptions,
  StyledGroupWrapper,
} from "_components/features/form/styles/FormStructs.styled";

import FormErrorMessage from "_components/features/form/elements/FormErrorMessage";
import FormOption from "_components/features/form/FormOption";
import FormItem from "_components/features/form/FormItem";
import FormCta from "_components/features/form/FormCta";

import { ENDPOINT } from "js/data/constants";
import { getErrorMessage } from "js/data/formErrorMessages";

function JoinProductionAccessKey() {
  const [errorMessages, setErrorMessages] = useState([]);

  const navigate = useNavigate();
  const formRef = useRef(null);

  const [canSendForm, setCanSendForm] = useState(true);

  const [isEditor, setIsEditor] = useState(false);
  const [isArtist, setIsArtist] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);

    if (!canSendForm) {
      const errMessage = "Form send in progress..";
      setErrorMessages([errMessage]);
      return;
    }

    const obj = {
      email: formRef.current.email.value,
      username: formRef.current.username.value,
      password: formRef.current.password.value,
      accessKey: formRef.current.access_key.value,
      accountType: "Production",
      isEditor: isEditor,
      isArtist: isArtist,
      // Can't be 'NULL' by how db is setup currently
      editSpeed: isEditor ? formRef.current.edit_speed.value : 0,
      thumbnailSpeed: isArtist ? formRef.current.thumbnail_speed.value : 0,
    };

    setCanSendForm(false);

    axios
      .post(`${ENDPOINT}/api/auth/user/signup`, obj)
      .then((res) => {
        let { hash } = res.data;
        if (!hash) {
          throw new Error(
            "Hash missing from production signup response on parsing!"
          );
        }
        console.log("successful production signup!");
        navigate(`/join/return?hash=${hash}&email=${obj.email}`);
      })
      .catch((error) => {
        setErrorMessages([getErrorMessage(error)]);
        setCanSendForm(true);
        console.error(error);
      });
  };

  return (
    <>
      <FormPage>
        <StyledFormContainer
          $shadowed
          ref={formRef}
          onSubmit={(e) => handleSubmit(e)}
        >
          <StyledFormTitle $bottom="10px" $width="90%">
            Join the Production Teams
          </StyledFormTitle>
          <FormItem
            $label="Email"
            placeholder="example@gmail.com"
            type="email"
            name="email"
            maxLength={320}
            required
            autoFocus
          />
          <FormItem
            $label="Username"
            placeholder="username"
            name="username"
            maxLength={26}
            required
          />
          <FormItem
            $label="Password"
            style={{ gridColumn: "1 / 2" }}
            placeholder="password"
            type="password"
            name="password"
            maxLength={50}
            required
          />
          <FormItem
            $label="Access Key"
            style={{ gridColumn: "2 / 3" }}
            placeholder="abc123#!"
            name="access_key"
            required
          />

          <StyledFormOptions>
            <FormOption
              value={!isEditor}
              name="isEditor"
              inputType="checkbox"
              selectedOption={isEditor}
              setSelectedOption={setIsEditor}
            >
              Join as Video Editor
            </FormOption>
            <FormOption
              value={!isArtist}
              name="isArtist"
              inputType="checkbox"
              selectedOption={isArtist}
              setSelectedOption={setIsArtist}
            >
              Join as a Thumbnail Artist
            </FormOption>
          </StyledFormOptions>

          {isEditor && (
            <FormItem
              $label="Edit Fulfillment Speed"
              style={{ gridColumn: isArtist && "1 / 2" }}
              placeholder="1-5 (days) (on average)"
              type="number"
              name="edit_speed"
              minLength={1}
              min={1}
              maxLength={5}
              max={5}
              required
            />
          )}
          {isArtist && (
            <FormItem
              $label="Thumbnail Fulfillment Speed"
              style={{ gridColumn: isEditor && "2 / 3" }}
              placeholder="1-3 (days) (on average)"
              type="number"
              name="thumbnail_speed"
              minLength={1}
              min={1}
              maxLength={3}
              max={3}
              required
            />
          )}

          {errorMessages.length > 0 && (
            <StyledGroupWrapper>
              {errorMessages.map((message, index) => (
                <FormErrorMessage message={message} key={index} />
              ))}
            </StyledGroupWrapper>
          )}

          {/* Provide spacing by activating gap of grid */}
          <div></div>
          <FormCta note="Certain info can be changed after signing up, via settings">
            Create Account
          </FormCta>
        </StyledFormContainer>
      </FormPage>
    </>
  );
}

export default JoinProductionAccessKey;
