import { useState, useEffect, useContext } from "react";
import axios from "axios";

import {
  StyledOverlayBackground,
  GlobalStyleOverride,
} from "_components/molecules/containers/overlays/styles/Overlay.styled";
import {
  StyledOverlayCard,
  StyledOverlayHeader,
  StyledExitBtn,
} from "_components/molecules/containers/overlays/styles/OverlayCard.styled";
import { StyledFlexbox } from "components/styles/SideBySide.styled";
import { StyledIncomingFile } from "_components/features/file/styles/FileElements.styled";

import LoadingStatus from "_components/atoms/info/LoadingStatus";
import { SquareCta } from "components/CtaElements";

import { AccountContext } from "components/contexts/AccountContext";
import { centeredComponent } from "_components/function/modifiers/areaPositions";
import { ENDPOINT } from "js/data/constants";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

const CenteredFlexbox = centeredComponent(StyledFlexbox);
const CenteredLoadingStatus = centeredComponent(LoadingStatus);
const CenteredSquareCta = centeredComponent(SquareCta);

const OfferEntry = ({ start, end, prefix, name, description }) => {
  const [loggedIn] = useContext(AccountContext);

  return (
    <CenteredFlexbox $justify="center" $align="center">
      <StyledIncomingFile style={{ width: "min(900px, 70%)" }} $light>
        <CenteredFlexbox $justify="center" $align="center">
          <span className="title">{prefix || "Offer"}</span>
          <h2 style={{ color: "var(--action-primary)" }}>{name}</h2>
        </CenteredFlexbox>
        <div>
          <p>
            <span className="title">Start Date: </span>
            {start}
          </p>
          <p>
            <span className="title">End Date: </span>
            {end}
          </p>
          <br />
          <p>
            <span className="title">Description: </span>
            {description}
          </p>
        </div>
        <br />
        {loggedIn ? (
          <StyledCenteredArea
            style={{
              color: "var(--sub-info-alt)",
              fontStyle: "italic",
            }}
          >
            Claimed
          </StyledCenteredArea>
        ) : (
          <CenteredSquareCta to="/join">Claim Now</CenteredSquareCta>
        )}
        <br />
      </StyledIncomingFile>
    </CenteredFlexbox>
  );
};

const OffersOverlay = ({ setActiveCardIndex }) => {
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${ENDPOINT}/api/global/drive/stats?name=Offers`, {
        responseType: "blob", // Set the response type to 'blob'
      })
      .then(async ({ data: blob }) => {
        setOffers(JSON.parse(await blob.text()));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Download Failed! Error", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <StyledOverlayBackground $overflow="scroll">
      <GlobalStyleOverride />
      <StyledOverlayCard>
        <StyledExitBtn onClick={() => setActiveCardIndex(false)}>
          <img src="/images/close-btn.png" />
        </StyledExitBtn>

        <StyledOverlayHeader $importantSub>
          <h1>Offers</h1>
          <p style={{ color: "var(--sub-info-dark)", maxWidth: "400px" }}>
            View our list of current free offers available to all, which will
            save you money and gain you value
          </p>
        </StyledOverlayHeader>

        <StyledFlexbox $justify="center" $gap="50px">
          {offers.length == 0 && (
            <CenteredLoadingStatus status={isLoading ? 0 : offers.length > 0} />
          )}
          {offers.map((data) => (
            <OfferEntry {...data} />
          ))}
        </StyledFlexbox>
      </StyledOverlayCard>
    </StyledOverlayBackground>
  );
};

export default OffersOverlay;
