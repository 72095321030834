import styled, { css, keyframes } from "styled-components";

export const btnInactiveStyle = css`
  --flag-col: grey;

  cursor: unset;

  img {
    filter: saturate(0);
    user-select: none;
  }
`;

export const btnHoverStyle = css<{ $enabled?: boolean; $offset?: string }>`
  &:hover:not(:active) {
    img {
      filter: ${(props) =>
        props.$enabled === true
          ? props.$enabled == undefined && "brightness(1.1)"
          : ""};
    }

    .hover-flag {
      top: ${(props) => props.$offset || "-50%"};
      opacity: 1;
    }

    &::before {
      height: 20px;
    }
  }
`;

export const btnStyle = css<{
  $important?: string;
  $flagWidth?: string;
  $offset?: string;
}>`
  --flag-col: var(--action-primary);
  --height: 25px;

  padding-right: var(--padding);
  display: block;
  position: relative;

  min-width: var(--height);
  height: var(--height);
  z-index: 2;

  transition: all 0.2s ease-in-out;

  cursor: pointer;

  ${(props) =>
    props.$important &&
    css`
      &::after {
        --important-radius: 5px;
        content: "";
        border-radius: 50%;
        background-color: red;
        height: calc(var(--important-radius) * 2);
        width: calc(var(--important-radius) * 2);
        position: absolute;
        display: block;
        top: calc(var(--important-radius) * -1);
        left: calc(var(--important-radius) * -1);
      }
    `}

  img {
    position: relative;
    height: 100%;
    transition: all 0.2s ease-in-out;
  }

  .hover-flag {
    display: block;
    position: absolute;

    /* Make sure it's hitbox isn't hit-able outside the icon/whatever it appears from hovering over before its visible */
    top: 0;
    opacity: 0;

    height: var(--height);
    width: ${(props) => props.$flagWidth};
    background-color: var(--flag-col);
    border-radius: 5px;
    padding: 5px;

    color: black;
    font-size: 0.9rem;

    transform: translate(calc(-50% + var(--height) / 2), calc(-100% - 12px));

    transition: all 0.2s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;
    // Prevents flag being able to be hovered to keep it showing. Only hovering button itself keeps flag showing
    pointer-events: none;

    &::after {
      content: "";
      bottom: -40%;
      background-color: var(--flag-col);
      position: absolute;
      display: block;

      height: 13px;
      width: 18px;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
`;

export const StyledHoverFlagBtn = styled.a<{
  $enabled?: boolean;
  $offset?: string;
}>`
  ${btnStyle}
  ${btnHoverStyle}
  ${(props) => !props.$enabled && btnInactiveStyle}
`;
