import { useState, useContext } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import HomeHeader from "_components/themes/brand/sections/HomeHeader";
import OurServices from "_components/themes/brand/sections/OurServices";
import AboutUs from "_components/themes/brand/sections/AboutUs";
import OurBrand from "_components/themes/brand/sections/OurBrand";
import CompanyStatView from "components/sections/CompanyStatView";
import SystemsOverview from "_components/themes/brand/sections/SystemsOverview";
import GamesPortal from "_components/themes/brand/sections/GamesPortal";
import Faq from "_components/themes/brand/sections/Faq";

import { StickyCta } from "components/CtaElements";
import StickyBackToTop from "components/objects/navigation/StickyBackToTop";

import { StickyCtaContext } from "components/contexts/StickyCtaContext";
import { AccountContext } from "components/contexts/AccountContext";

const Home = () => {
  const [isStickiesEnabled, setIsStickiesEnabled] = useState(false);
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  return (
    <>
      <StickyCta to="/join" $isEnabled={!loggedIn && isStickiesEnabled}>
        Get Started
      </StickyCta>
      <StickyBackToTop $isEnabled={isStickiesEnabled} to="home-header" />

      <StickyCtaContext.Provider
        value={[isStickiesEnabled, setIsStickiesEnabled]}
      >
        <HomeHeader name="home-header" />
      </StickyCtaContext.Provider>
      <OurServices />
      <AboutUs name="about-us" />
      <OurBrand name="our-brand" />
      <CompanyStatView />

      <StyledStandardSpacer $paddingTop="400px" />
      <SystemsOverview />

      <StyledStandardSpacer $paddingTop="400px" />
      <GamesPortal />

      <Faq name="faq" />
    </>
  );
};

export default Home;
