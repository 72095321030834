import React from "react";

import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import InfoStack from "_components/molecules/containers/common/InfoStack";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const KeyInformation = () => {
  return (
    <InfoStack $title="Key Information" $col="var(--contrast-one-dark)">
      <div>
        <h3>Intro</h3>
        <br />
        <p>
          When we say 'Production Teams' we are referring to the teams of video
          editors and the teams of thumbnails artists who are created for each
          client in order to help produce their Youtube videos.
        </p>
        <p>
          You can chat individually with each editor or artist, chat with
          editors only, chat with artists only, or you can chat with all members
          of the team in one chat room. All of this can be done through the '
          <LinkSpan $col="var(--contrast-one-dark)" to="/chat">
            Chat
          </LinkSpan>
          ' page.
        </p>
        <p>
          This half of our brand's identity can be described as a 'Roblox
          Editing & Thumbnail production Agency'. You can view and understand
          the other half of our brand identity in '
          <LinkSpan $col="var(--contrast-one-dark)" to="/benefits/game">
            Game Benefits
          </LinkSpan>
          ' .
        </p>
      </div>
    </InfoStack>
  );
};

export default KeyInformation;
