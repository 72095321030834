import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";
import PartnerPlans from "_components/features/checkout/PartnerPlans";

const Pricing = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h1>Pricing</h1>
        <p style={{ maxWidth: "400px" }}>
          View the tiered and alternate package options, differing in pricing
          and rewards
        </p>
      </TopHeadingBanner>
      <StyledStandardSpacer $paddingTop="50px" />
      <PartnerPlans />

      <StyledStandardSpacer $paddingTop="300px" />
    </StyledStandardEndSection>
  );
};

export default Pricing;
