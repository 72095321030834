import { useContext, useState } from "react";

import { StyledGfxCircle, StyledCircle } from "./GfxCircle.styled";
import { StyledAspectRatioContainer } from "components/styles/AspectRatioContainer.styled";

import { QueryContext } from "components/contexts/QueryContext";
import OffersOverlay from "_components/molecules/containers/common/OffersOverlay";

const CircleGfx = ({ showBubble = false, isHovering, children, ...props }) => {
  const { queryWrap } = useContext(QueryContext);

  return (
    <StyledGfxCircle {...props}>
      <div className="center" style={{ zIndex: 1 }}>
        <img
          src="/images/soldiers-cutout.png"
          style={{ opacity: isHovering ? 0 : 1 }}
        />
        {showBubble && (
          <img
            src="/images/gfx/pixel-speech-bubble.png"
            style={{ height: "50px", left: "-90px", top: "10px" }}
          />
        )}
        <img
          src="/images/soldiers-cutout-salute.png"
          style={{ opacity: isHovering ? 1 : 0 }}
        />
      </div>
      {children}
    </StyledGfxCircle>
  );
};

export const CircleGfxButton = ({ isHovering }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {isActive && (
        <OffersOverlay
          setActiveCardIndex={setIsActive}
          handleExitClick={() => setIsActive(false)}
        />
      )}
      <CircleGfx
        $radius="200px"
        $glowSize="0px"
        $glowSpread="10px"
        $free
        showBubble
        isHovering={isHovering}
        onClick={() => setIsActive(true)}
        style={{ cursor: "pointer" }}
      ></CircleGfx>
    </>
  );
};

const CircleGfxDisplay = ({ isHovering }) => {
  const { queryWrap } = useContext(QueryContext);

  return (
    <StyledAspectRatioContainer
      $aspectRatio={queryWrap.matches ? "1:1" : null}
      // Provides space for 'next-to' element without losing size
      $width={!queryWrap.matches ? "30%" : "100%"}
      $height="1000px"
      style={{
        marginBottom: queryWrap.matches ? "300px" : null,
      }}
    >
      <CircleGfx
        $autoResponsive
        className="ratio-child"
        isHovering={isHovering}
        style={{
          // Compensating loss from StyledAspectRatioContainer $width
          paddingRight: !queryWrap.matches && "20%",
          paddingTop: queryWrap.matches && "550px",
        }}
      >
        <svg
          viewBox="0 0 800 800"
          // height={!queryWrap.matches ? "100%" : null}
          height="100%"
          width={queryWrap.matches ? "100%" : null}
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          style={{ position: "absolute" }}
        >
          {/* 6 */}
          <StyledCircle
            $delay="1.8s"
            cx="360"
            cy="170"
            fill="#003A91"
            r="150"
          />
          {/* 3 */}
          <StyledCircle
            $delay="0.7s"
            cx="260"
            cy="640"
            fill="#6D3AFE"
            r="150"
          />
          {/* 2 */}
          <StyledCircle
            $delay="1.2s"
            cx="180"
            cy="450"
            fill="#9A00FF"
            r="150"
          />
          {/* 1 */}
          <StyledCircle $delay="0s" cx="200" cy="190" fill="#6D3AFE" r="150" />
          {/* 4 */}
          <StyledCircle
            $delay="2.3s"
            cx="620"
            cy="500"
            fill="#000087"
            r="150"
          />
          {/* 5 */}
          <StyledCircle
            $delay="2.5s"
            cx="630"
            cy="160"
            fill="#9A00FF"
            r="150"
          />
        </svg>
      </CircleGfx>
    </StyledAspectRatioContainer>
  );
};

export default CircleGfxDisplay;
