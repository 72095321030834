import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  StyledSidebarredBlock,
  StyledDisplayArea,
} from "_components/molecules/containers/common/styles/SidebarredBlock.styled";

import ConfirmSelectScreen from "_components/molecules/containers/overlays/ConfirmSelectScreen";
import EnterValueScreen from "_components/molecules/containers/overlays/EnterValueScreen";
import InputItem from "_components/features/form/InputItem";
import { ThinBtn } from "_components/atoms/buttons/ThinBtnElements";

import SettingChangeScreen from "../SettingChangeScreen";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const confirmSelectScreenInfo = {
  confirmed: {
    title: "Setting Successfully Changed!",
    description: <p>You setting was successfully changed to your new value!</p>,
  },
  prompt: {
    title: "Confirm Setting Change",
    description: <p>Are you sure you want to change this setting?</p>,
  },
};

const ConnectedAccounts = ({ dbName, columnName }) => {
  const [newValue, setNewValue] = useState("");

  const [isChangingSetting, setIsChangingSetting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const loadUserData = async () => {
    axios
      .get(`${ENDPOINT}/api/user/data?method=id&db=${dbName}`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const { PayPal } = res.data;
        setNewValue(PayPal);
        setIsConnected(PayPal != "");
      })
      .catch(console.error);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <>
      <SettingChangeScreen
        dbName={dbName}
        columnName={columnName}
        placeholder="example.paypal.me"
        newValue={newValue}
        setNewValue={setNewValue}
        isChangingSetting={isChangingSetting}
        setIsChangingSetting={setIsChangingSetting}
      />

      <StyledSidebarredBlock>
        <StyledDisplayArea $centered $grow="1" $top="left" $dark>
          <img
            src="/images/apps/paypal-logo.png"
            style={{
              height: "100%",
            }}
          />
        </StyledDisplayArea>

        <StyledDisplayArea $grow="2">
          <p>
            {isConnected
              ? "Your PayPal account is connected"
              : "Connect your PayPal account to receive payments from us for your work"}
          </p>
          <ThinBtn
            $primary={
              isConnected
                ? "var(--main-secondary-alt)"
                : "var(--action-primary)"
            }
            onClick={() => setIsChangingSetting(true)}
          >
            {isConnected ? "Change" : "Connect"}
          </ThinBtn>
        </StyledDisplayArea>
      </StyledSidebarredBlock>
    </>
  );
};

export default ConnectedAccounts;
