import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import { StyledXStrip } from "_components/themes/brand/XStrip.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledFlexbox, StyledSbs } from "components/styles/SideBySide.styled";

import {
  StyledLineInfoBlock,
  StyledLineInfoBlockP,
} from "_components/molecules/containers/common/styles/StyledInfoBlock.styled";

import { LinkSpan } from "_components/atoms/inline/SpanElements";
import { SectionHeading } from "_components/atoms/common/styles/SectionHeadings.styled";
import PackageOption from "_components/molecules/displays/PackageOption";

const dividers = ["You Want"];

const partner = [
  [
    ["To grow your channel"],
    ["To collaborate with larger YouTubers"],
    ["To upload more frequently"],
    ["To have higher quality videos"],
    ["To have higher quality thumbnails"],
    [
      "To earn passive income from multiple Roblox games of your choice, constantly (larger cuts available)",
    ],
    [
      "To receive effective, free advertising and marketing for your brand across Roblox, and social media constantly",
    ],
    ["To have a personal team of video editors and/or thumbnail artists"],
    [
      "To have access to our entire site, rewards, special offers and new features that are introduced",
    ],
    [
      "To be directly associated with our brand as we grow, promoting your brand where ever our brand appears",
    ],
    ["To be partnered with each Roblox game you choose"],
  ],
];

const affiliate = [
  [
    [
      "To earn passive income from a Roblox game of your choice, constantly (smaller cut)",
    ],
    [
      "To earn passive income from sales made on our site by Partners who joined via your Affiliate Link",
    ],
  ],
];

const affiliateDividers = ["Features", "Stats", "Requirements"];
const affiliatePlan = [
  [
    ["Game Profits", true],
    ["Free Advertising", false],
    ["Collaborations", false],
    ["Personal Production Team", false],
    ["Out-of-game Advertising", false],
    ["In-game Advertising", false],
    ["Access To All Special Offers", false],
    ["Access To All Site Features", false],
    ["Priority Privilege", false],
    ["Site Profits", true],
  ],
  [
    ["Minimum Percentage Ownership Per-Game", "1%"],
    ["Maximum Percentage Ownership Per-Game", "1/5"],
    ["Maximum Games Selectable", "1"],
    ["Percentage Cut Of Site Sale Profits", "20%"],
  ],
  [["Minimum YouTube Subscriber Count", "100,000"]],
];

const AffiliateProgram = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/barrumble-gfx.jpg">
        <h1>The Affiliate Program</h1>
        <p>All information regarding being an Affiliate with our company</p>
      </TopHeadingBanner>
      <StyledStandardSpacer $paddingTop="200px" />

      <StyledXStrip $pull={3} style={{ padding: "100px 0" }}>
        <StyledCenteredArea>
          <StyledFlexbox style={{ width: "90%" }}>
            <StyledLineInfoBlock
              $colBack="var(--main-background)"
              $maxWidth="min(700px, 80%)"
              $addedHeight="50px"
            >
              <h1 style={{ margin: "0 0 30px 0" }}>Overview</h1>
              <h2 style={{ margin: "0" }}>What It Is</h2>
              <StyledLineInfoBlockP>
                Like you can guess from the word "Affiliate", you will be
                "affiliated" with our company by signing up as an Affiliate.
                This differs from a normal signup on our site as a "Partner".
              </StyledLineInfoBlockP>
              <h2 style={{ margin: "0" }}>How It Works</h2>
              <StyledLineInfoBlockP>
                Upon creation of your "Affiliate" account. You receive a
                personal "affiliate link" on your dashboard. You are then able
                to share this link and promote it in order to get aspiring and
                upcoming youtubers to signup to our site as a "Partner", so that
                we can then help them to grow their brand by providing them with
                our many services.
              </StyledLineInfoBlockP>

              <StyledLineInfoBlockP>
                <b>Commission Payouts</b>
                <br />
                Each Partner that signs up using your "affiliate link" will earn
                you passive income by making purchases on our site. Whenever
                they purchase a video edit or a video thumbnail, you will
                receive a percentage commission from each sale made, paid into
                whatever payment account you linked in{" "}
                <LinkSpan to="/profile?tab=payment">settings</LinkSpan>.
              </StyledLineInfoBlockP>

              <StyledLineInfoBlockP>
                <b>Game Profits</b>
                <br />
                Secondly, just like our Partners do, you get access to the{" "}
                <LinkSpan to="/game/manager">game manager</LinkSpan>. This
                allows you select from a list our constantly growing collection
                of games we either own, manage or own shares of. From these
                games, you can select which one you want to be involved in, or
                change your selection monthly.
              </StyledLineInfoBlockP>

              <StyledLineInfoBlockP>
                This selection of involvement will make you an instant, official
                shareholder in the game's monthly profits. The amount of
                percentage you own of the game's monthly profits can be
                increased by advertising our brand, but in a slightly different
                way! We review your advertising of our brand when you submit it
                manually into the{" "}
                <LinkSpan to="/game/booster">ownership booster</LinkSpan>. We
                then choose whether the advertising matches one of the{" "}
                <LinkSpan to="/game/booster">advertising options</LinkSpan>{" "}
                available, and either accept or reject your request. If your
                request is accepted, your percentage ownership of the selected
                game will increase, earning you more money from the game's
                success!
              </StyledLineInfoBlockP>
            </StyledLineInfoBlock>
          </StyledFlexbox>
        </StyledCenteredArea>
      </StyledXStrip>
      <StyledStandardSpacer $paddingTop="400px" />

      <StyledXStrip $push={3}>
        <SectionHeading
          title="Affiliate vs. Partner"
          description="Learn which account type you should signup with based on what you might be want"
        />
        <StyledStandardSpacer $paddingTop="50px" />
        <StyledSbs $align="start" $gap="min(50px, 5%)">
          <PackageOption
            title="Partner"
            description="When a production team is needed"
            dividers={dividers}
            entries={partner}
            alt
          />
          <PackageOption
            title="Affiliate"
            description="When a production team is not needed"
            dividers={dividers}
            entries={affiliate}
            alt
          />
        </StyledSbs>
      </StyledXStrip>
      <StyledStandardSpacer $paddingTop="400px" />

      <StyledDualXStrip $pad>
        <StyledCenteredArea>
          <PackageOption
            title="Affiliate Plan"
            description="Earn without spending"
            dividers={affiliateDividers}
            entries={affiliatePlan}
            to="/join?type=Affiliate"
            alt
          />
        </StyledCenteredArea>
      </StyledDualXStrip>
    </StyledStandardEndSection>
  );
};

export default AffiliateProgram;
