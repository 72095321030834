import React, { useState, useEffect, useContext } from "react";

import Gallery from "../../../components/objects/gallery/Gallery";

import { StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledAspectRatioContainer,
  StyledAspectRatioWrapper,
} from "../../../components/styles/AspectRatioContainer.styled";

import { StyledTestimonial } from "components/styles/objects/Testimonial.styled";

import { StickyCtaContext } from "components/contexts/StickyCtaContext";
import { QueryContext } from "components/contexts/QueryContext";

import { StyledSpacedTabsContainer } from "components/styles/objects/common/TabElements.styled";
import { Tab } from "components/objects/common/Tab";

import {
  StyledOverlayBackground,
  GlobalStyleOverride,
} from "_components/molecules/containers/overlays/styles/Overlay.styled";
import {
  StyledOverlayCard,
  StyledOverlayHeader,
  StyledExitBtn,
} from "_components/molecules/containers/overlays/styles/OverlayCard.styled";
import {
  StyledInfoContainer,
  StyledInfoGrid,
  StyledInfoBlock,
} from "./styles/Stats.styled";

import UserStats from "./blocks/UserStats";
import GameStats from "./blocks/GameStats";

import SelectBtn from "./buttons/SelectBtn";
import UnselectBtn from "./buttons/UnselectBtn";
import Testimonial from "_components/molecules/displays/Testimonial";

const InfoContainer = ({ $headed, title, children: text, ...props }) => {
  return (
    <StyledInfoContainer $headed={$headed} {...props}>
      {$headed ? (
        <h2 className="heading">{title}</h2>
      ) : (
        <p className="heading">{title}</p>
      )}
      <p className="info" style={{ whiteSpace: "pre-line" }}>
        {text}
      </p>
    </StyledInfoContainer>
  );
};

const GameCardOverlay = ({ setActiveCardIndex, data, status }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { queryWrap } = useContext(QueryContext);
  const [_isStickiesEnabled, setIsStickiesEnabled] =
    useContext(StickyCtaContext);

  // Only continues to exit if that exact object was clicked and not child
  const handleExitClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setActiveCardIndex(-1);
    }
  };

  useEffect(() => {
    if (setIsStickiesEnabled) {
      setIsStickiesEnabled(false);
      return () => !queryWrap.matches && setIsStickiesEnabled(true);
    }
  }, []);

  const gameStats = [];
  for (const name in data.stats) {
    const value = data.stats[name];
    gameStats.push({ name: name, value: value });
  }

  const testimonials = [];
  for (const name in data.testimonials) {
    const [type, quote, url] = data.testimonials[name];
    testimonials.push({ name: name, type: type, quote: quote, url: url });
  }

  // Banner is hidden due to z-index = -1 on it, putting it behind background. This works to hide it as we don't need it
  return (
    <StyledOverlayBackground $overflow="scroll" onClick={handleExitClick}>
      <GlobalStyleOverride />
      <StyledOverlayCard>
        <StyledExitBtn onClick={() => setActiveCardIndex(-1)}>
          <img src="/images/close-btn.png" />
        </StyledExitBtn>

        <StyledOverlayHeader $importantSub>
          <h1>Game Overview</h1>
          <p>Title - {data.game}</p>
        </StyledOverlayHeader>

        {/* Only display status when it was given */}
        {status &&
          (status === "Unselected" ? (
            <SelectBtn
              gameTitle={data.game}
              setActiveCardIndex={setActiveCardIndex}
            />
          ) : (
            <UnselectBtn
              gameTitle={data.game}
              setActiveCardIndex={setActiveCardIndex}
            />
          ))}

        <StyledAspectRatioWrapper
          $maxHeight={queryWrap.matches ? "850px" : "650px"}
        >
          <StyledAspectRatioContainer
            $aspectRatio={queryWrap.matches ? "1:1" : "2:1"}
          >
            <Gallery
              className="ratio-child"
              style={{ height: "70%" }}
              title={data.game}
            />
          </StyledAspectRatioContainer>
        </StyledAspectRatioWrapper>
        <StyledSbs
          $gap="20px"
          $align="center"
          $justify="start"
          $centered="90%"
          $column
          style={{
            backgroundColor: "var(--main-secondary)",
            padding: "30px",
            borderRadius: "15px",
          }}
        >
          <StyledSpacedTabsContainer $darkBack>
            <Tab
              $bgLight
              $index={0}
              $currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              Overview
            </Tab>
            <Tab
              $bgLight
              $index={1}
              $currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              Stats
            </Tab>
            {testimonials.length > 0 && (
              <Tab
                $bgLight
                $index={2}
                $currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              >
                Testimonials
              </Tab>
            )}
          </StyledSpacedTabsContainer>

          {currentTab === 0 && (
            <StyledInfoGrid>
              <StyledInfoBlock $maxWidth="30%" $minWidth="300px">
                <InfoContainer title="Game">{data.game}</InfoContainer>
                <InfoContainer title="Studio">{data.studio}</InfoContainer>
              </StyledInfoBlock>
              <StyledInfoBlock $maxWidth="60%" $minWidth="300px">
                <InfoContainer
                  title="Description"
                  style={{ gridColumn: "1 / -1" }}
                >
                  {data.description}
                </InfoContainer>
              </StyledInfoBlock>
            </StyledInfoGrid>
          )}

          {currentTab === 1 && (
            <StyledSbs style={{ alignItems: "start", width: "100%" }}>
              <GameStats gameStats={gameStats} gameTitle={data.game} />
              {status === "Selected" && <UserStats gameTitle={data.game} />}
            </StyledSbs>
          )}

          {currentTab === 2 && (
            <StyledSbs
              $align="start"
              $defaultWrap
              $gap="20px"
              style={{
                borderRadius: "10px",
                width: "100%",
                padding: "30px",
                backgroundColor: "var(--main-background)",
              }}
            >
              {testimonials.map((data) => (
                <Testimonial data={data} />
              ))}
            </StyledSbs>
          )}
        </StyledSbs>
      </StyledOverlayCard>
    </StyledOverlayBackground>
  );
};

export default GameCardOverlay;
