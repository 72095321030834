import { NavigateFunction } from "react-router-dom";

export type ErrorMessage = string | string[] | [string, any[]];

const formErrorMessages: { [messageId: string]: ErrorMessage } = {
  invalid_username: "Invalid username given!",
  username_forbidden: "Username not allowed! No numbers in beginning.",
  invalid_email: "Invalid email given!",
  username_taken: "Username is taken!",
  max_accounts:
    "Maximum accounts reached on our site! Email us for a space at 'contact@pushxpull.co'",

  wrong_login: "Incorrect login details!",
  unverified_client: [
    "Your account is not verified! | Verify it here",
    [null, "/join/verify"],
  ],
  unverified_staff: [
    "Your account is not verified! | Verify it here",
    [
      null,
      (search: URLSearchParams) =>
        `/join/return?username=${search.get("username")}`,
    ],
  ],

  unpaid_thumbnails: [
    "You have not paid for any thumbnails in order to request one! | Manage Payments",
    [null, "/profile?tab=payment"],
  ],
  unpaid_edits: [
    "You have not paid for any edits in order to request one! | Manage Payments",
    [null, "/profile?tab=payment"],
  ],
  staff_unavailable: [
    "No staff currently available on your team to handle this request, so it failed! Try again when one of your staff members are 'available' by checking your | Team View",
    [null, "/team"],
  ],

  form_incomplete: "Please fill in the missing fields!",
};

/*
  Attempts
  1. Directly indexing the ErrorMessage via the given error code
  2. Breaking up the error message into queries. If it succeeds, it updates the url query for other code to react to data.
*/
export function getErrorMessage(
  error: any,
  navigate?: NavigateFunction,
  pathname?: string
): ErrorMessage | undefined {
  let errorMessage: string =
    typeof error === "string" ? error : error?.response?.data?.error;

  // Error code
  if (formErrorMessages[errorMessage]) return formErrorMessages[errorMessage];

  // Just message
  if (!navigate || !pathname) return errorMessage;

  let queries: string[] | string = errorMessage.split("&");
  errorMessage = queries[0];
  queries.splice(0, 1);
  queries = queries.join("&");
  navigate(pathname + "?" + queries);
  // Message with path
  return formErrorMessages[errorMessage];
}

export default formErrorMessages;
