import type { ErrorMessage } from "js/data/formErrorMessages";

import { StyledFormP } from "../styles/FormStructs.styled";

import { mappedErrorMessages } from "_components/function/modifiers/errorMessages";
import { LinkSpan } from "_components/atoms/inline/SpanElements";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

type ErrorMessageProps = {
  [key: string]: any;
  message: ErrorMessage;
  key: string;
};

type LinkEmbedProps = {
  link: string | ((search: URLSearchParams) => string);
  children: string;
};

const LinkEmbed = ({ link, children: msg }: LinkEmbedProps) => {
  const { search } = useLocation();
  const [currentLink, setCurrentLink] = useState("");

  useEffect(() => {
    setCurrentLink(
      typeof link === "function" ? link(new URLSearchParams(search)) : link
    );
  }, [search]);

  return (
    <>
      {" "}
      <LinkSpan to={currentLink}>{msg}</LinkSpan>{" "}
    </>
  );
};

const FormErrorMessage = ({ message, key, ...props }: ErrorMessageProps) => {
  const mappedErrors = mappedErrorMessages(message, LinkEmbed);
  return (
    <StyledFormP $type="error" $margin="0" key={key} {...props}>
      {mappedErrors}
    </StyledFormP>
  );
};

export default FormErrorMessage;
