const thumbnails = [
  {
    original: "/content/thumbnails/strucid-1.jpg",
    thumbnail: "/content/thumbnails/strucid-1.jpg",
    type: "thumbnail",
  },
  {
    original: "/content/thumbnails/strucid-6.jpg",
    thumbnail: "/content/thumbnails/strucid-6.jpg",
    type: "thumbnail",
  },
  {
    original: "/content/thumbnails/strucid-5.jpg",
    thumbnail: "/content/thumbnails/strucid-5.jpg",
    type: "thumbnail",
  },
  {
    original: "/content/thumbnails/l3oteck_-fiverr-2.jpg",
    thumbnail: "/content/thumbnails/l3oteck_-fiverr-2.jpg",
    type: "thumbnail",
  },
  {
    original: "/content/thumbnails/strucid-2.jpg",
    thumbnail: "/content/thumbnails/strucid-2.jpg",
    type: "thumbnail",
  },
  {
    original: "/content/thumbnails/strucid-3.jpg",
    thumbnail: "/content/thumbnails/strucid-3.jpg",
    type: "thumbnail",
  },
  {
    original: "/content/thumbnails/strucid-4.jpg",
    thumbnail: "/content/thumbnails/strucid-4.jpg",
    type: "thumbnail",
  },
  {
    original: "/content/thumbnails/l3oteck_-fiverr-1.jpg",
    thumbnail: "/content/thumbnails/l3oteck_-fiverr-1.jpg",
    type: "thumbnail",
  },
];

export default thumbnails;
