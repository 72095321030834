const edits = [
  {
    original:
      "https://player.vimeo.com/video/822357498?h=bc93f52244&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed",
    thumbnail: "/content/edit-covers/deepwoken-leon.jpg",
    type: "video",
  },
  {
    original:
      "https://player.vimeo.com/video/832257002?h=adefed750d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    thumbnail: "/content/edit-covers/deepwoken-warrior.jpg",
    type: "video",
  },
  {
    original:
      "https://player.vimeo.com/video/822360453?h=08988480c4&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed",
    thumbnail: "/content/edit-covers/vr-leon.jpg",
    type: "video",
  },
];

export default edits;
