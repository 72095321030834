import styled, { css, keyframes } from "styled-components";

const offAnimation = keyframes`
  0% {
    content: "Yes";
    color: var(--action-primary);
    transform: translateX(0);
  }
  30%, 70% {
    opacity: 0;
    color: var(--action-primary);
  }
  100% {
    opacity: 1;
    transform: translateX(calc(-1 * var(--width) + 100%));
    color: var(--sub-info-alt);
    content: "No";
  }
`;

const onAnimation = keyframes`
  0% {
    content: "No";
    transform: translateX(calc(-1 * var(--width) + 100%));
    color: var(--sub-info-alt);
  }
  30%, 70% {
    opacity: 0;
    color: var(--sub-info-alt);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    color: var(--action-primary);
    content: "Yes";
  }
`;

// Must us css instead of inline to interpolate. Inline is not supported for this
const onStyle = css`
  animation: calc(var(--duration) + 0.13s) ease-in-out ${onAnimation} forwards;
`;

const offStyle = css`
  animation: calc(var(--duration) + 0.13s) ease-in-out ${offAnimation} forwards;
`;

export const StyledSwitchFlip = styled.div<{
  $enabled?: boolean;
  $text?: string;
}>`
  --width: 65px;
  --radius: 23px;
  --duration: 0.3s;

  // Background
  background-color: var(--main-background);
  border-radius: 50px;

  height: 30px;
  width: var(--width);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    &::before {
      scale: 0.9;
    }
  }

  // Icon
  &::before {
    content: "";
    background-color: ${(props) =>
      props.$enabled ? "var(--action-primary)" : "var(--sub-info-alt)"};
    display: block;
    position: relative;
    width: var(--radius);
    height: var(--radius);
    border-radius: 50%;
    transform: ${(props) =>
      props.$enabled
        ? "unset"
        : "translateX(calc(var(--width) - var(--radius)))"};
    transition: scale 0.1s ease-in-out, transform var(--duration) ease-in-out;
  }

  // Text
  &::after {
    width: calc(var(--width) - var(--radius));
    height: 100%;

    content: "";
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    ${(props) => (props.$enabled ? onStyle : offStyle)};
  }
`;
