import styled from "styled-components";

import { DashedUnderlineStyle } from "_components/atoms/inline/styles/Decorations.styled";

export const StyledDottedLinkText = styled.p`
  color: var(--action-primary);
  position: relative;
  width: min(80%, 350px);
  text-align: center;
  padding-bottom: 10px;

  cursor: pointer;

  &:hover::after {
    --main-col: var(--action-primary);
  }

  &::after {
    ${DashedUnderlineStyle};
    --invis-col: var(--main-secondary);
    bottom: 0;
  }
`;
