import styled from "styled-components";

import { StyledFlexbox } from "components/styles/SideBySide.styled";

export const StyledSectionH2 = styled.h2`
  font-size: 35px;
  margin: 0;
`;

export const SectionHeading = ({ title, description }) => {
  return (
    <StyledFlexbox $justify="center" $align="center">
      <StyledSectionH2>{title}</StyledSectionH2>
      {description && (
        <p style={{ maxWidth: "350px", textAlign: "center" }}>{description}</p>
      )}
    </StyledFlexbox>
  );
};
