import React from "react";

import { StyledHighlightedSpan } from "_components/atoms/inline/styles/SpanButtons.styled";

import InfoStack from "_components/molecules/containers/common/InfoStack";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const KeyInformation = () => {
  return (
    <InfoStack $title="Key Information" $col="var(--contrast-one-dark)">
      <div className="block">
        <h3>Intro</h3>
        <p>
          Using the form below, you can present us with proof that you
          advertised one of our games, which you are have selected to be
          involved in.
        </p>
        <p>
          We will manually review the request, and if your provided proof
          matches the with the selected method(s) of advertising, you will
          receive an increase in ownership percentage for that game when the
          request is reviewed.
        </p>
        <p>
          You will receive and email of the result of the request after 1-3 days
          of submitting the form.
        </p>
        <p>
          To learn everything you need to know about how our{" "}
          <StyledHighlightedSpan $col="var(--contrast-one-dark)">
            ownership percentage system
          </StyledHighlightedSpan>{" "}
          functions, see '
          <LinkSpan $col="var(--contrast-one-dark)" to="/benefits/game">
            Game Benefits
          </LinkSpan>
          '.
        </p>
      </div>

      <div className="block">
        <h3>Advertising Options</h3>
        <p>Social Media Post</p>
        <p>Video Plug</p>
        <p>Description/Comment Plug</p>
        <p>Dedicated Video</p>
        <p>Other</p>
      </div>
    </InfoStack>
  );
};

export default KeyInformation;
