import React from "react";

import {
  StyledProIndentP,
  StyledConIndentP,
} from "_components/atoms/inline/styles/Decorations.styled";
import {
  StyledThinInfoPanel,
  StyledPanelHeading,
  StyledPanelContainer,
} from "_components/molecules/containers/common/styles/ThinInfoPanel.styled";

export const BrandComparisonPrimary = () => {
  return (
    <StyledThinInfoPanel>
      <StyledPanelHeading $highlighted>
        <h2>Working with us</h2>
        <p>As a Video Editor or Thumbnail Artist</p>
      </StyledPanelHeading>
      <StyledPanelContainer $highlighted>
        <StyledProIndentP>
          We don't need over 50 staff. You get complete attention and have any
          needs met from us directly. You're in a quiet pond of fish with no
          competition, when working with us
        </StyledProIndentP>
        <StyledProIndentP>
          You can work by your schedule. Other staff can fill in for you when
          you can't meet a deadline
        </StyledProIndentP>
        <StyledProIndentP>
          Your income only goes up. It is bound to increase over time by working
          with us through our unique system
        </StyledProIndentP>
        <StyledProIndentP>
          Never be stuck without work. There will almost always be work to do
          for any of our clients, available for you to take on whenever you want
        </StyledProIndentP>
        <StyledProIndentP>
          Gain permanent, real connections with upcoming and large youtubers who
          work with you through our company which could help you in the future
        </StyledProIndentP>
        <StyledProIndentP>
          You only have to focus on Roblox related production. Making you excel
          at it over time through practice and not require you to jump from game
          to game
        </StyledProIndentP>
      </StyledPanelContainer>
    </StyledThinInfoPanel>
  );
};

export const BrandComparisonSecondary = () => {
  return (
    <StyledThinInfoPanel>
      <StyledPanelHeading>
        <h2>Working on other Sites</h2>
        <p>As a Video Editor or Thumbnail Artist</p>
      </StyledPanelHeading>
      <StyledPanelContainer>
        <StyledConIndentP>
          Places you in an ocean of competition, making you just another number
          in their system to fend for yourself
        </StyledConIndentP>
        <StyledConIndentP>
          Missing a deadline results in negative reviews from clients and
          massive stress when trying to also fulfill even more orders
        </StyledConIndentP>
        <StyledConIndentP>
          Your pricing is fixed. You have to charge low to match your
          competition and you can't increase it by much almost ever
        </StyledConIndentP>
        <StyledConIndentP>
          Work can be either quiet or noisy. It's inconsistent and can leave you
          with no money coming in if you aren't getting clients yourself
        </StyledConIndentP>
        <StyledConIndentP>
          Hard to gain real connections if any, with big youtubers as you are
          usually not their full-time editor/artist
        </StyledConIndentP>
        <StyledConIndentP>
          You have to jump from different games constantly with your production,
          not honing your skills into becoming exceptional at any one in
          particular
        </StyledConIndentP>
      </StyledPanelContainer>
    </StyledThinInfoPanel>
  );
};
