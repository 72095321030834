import styled from "styled-components";

import { StyledFlexbox, StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledThinInfoPanel,
  StyledPanelHeading,
  StyledPanelContainer,
} from "_components/molecules/containers/common/styles/ThinInfoPanel.styled";
import { StyledArrowIndentP } from "_components/atoms/inline/styles/Decorations.styled";

import { SquareCta, SquareCtaAlt } from "components/CtaElements";

interface LonePackageHeader {
  $highlighted?: boolean;
  title: string;
  description: string[];
}

interface PackageOption extends LonePackageHeader {
  dividers?: string[];
  entries?: string[][];
  to?: string;
  alt: boolean;
}

const StyledDivider = styled(StyledFlexbox)`
  position: relative;
  color: var(--sub-info-alt);
  padding-bottom: 15px;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--sub-info-alt);
    bottom: 10px;
    position: absolute;
    display: block;
  }
`;

const EntryValue = ({ value }: { value: boolean | string }) => {
  if (typeof value === "boolean") {
    return (
      <img
        style={{ width: value ? "20px" : "30px" }}
        src={
          value ? "/images/buttons/select-icon.png" : "/images/close-btn.png"
        }
      />
    );
  }
  return (
    <p style={{ color: "var(--action-primary)", textAlign: "end" }}>{value}</p>
  );
};

export const LonePackageHeader = ({
  $highlighted,
  title,
  description,
}: LonePackageHeader) => {
  return (
    <StyledThinInfoPanel style={{ width: "500px" }}>
      <StyledPanelHeading
        $highlighted={$highlighted}
        style={{ borderRadius: "10px" }}
      >
        <h2>{title}</h2>
        {description && <p>{description}</p>}
      </StyledPanelHeading>
    </StyledThinInfoPanel>
  );
};

const PackageOption = ({
  $highlighted,
  title,
  description,
  dividers = [],
  entries = [[]],
  to,
  alt,
}: PackageOption) => {
  return (
    <StyledThinInfoPanel style={{ width: "500px" }}>
      <StyledPanelHeading $highlighted={$highlighted}>
        <h2>{title}</h2>
        {description && <p>{description}</p>}
      </StyledPanelHeading>
      <StyledPanelContainer $padding="30px" $highlighted={$highlighted}>
        <StyledFlexbox $align="center">
          {dividers.map((divider, index) => (
            <StyledFlexbox
              $align="space-between"
              style={{ width: "100%", marginBottom: "30px" }}
            >
              <StyledDivider $align="center" $justify="center">
                {divider}
              </StyledDivider>
              {entries[index].map(([name, value, description]) => (
                <StyledSbs $justify="space-between" $align="center">
                  <StyledFlexbox $justify="center">
                    <StyledArrowIndentP
                      $shape="bullet"
                      $indent
                      $col="var(--sub-info-alt)"
                      style={{ color: "var(--sub-info-alt)" }}
                    >
                      {name}
                      {description &&
                        description.map((text) => (
                          <>
                            <br />
                            <span style={{ opacity: 0.8 }}>{text}</span>
                          </>
                        ))}
                    </StyledArrowIndentP>
                  </StyledFlexbox>
                  <EntryValue value={value} />
                </StyledSbs>
              ))}
            </StyledFlexbox>
          ))}
        </StyledFlexbox>
        {to &&
          (alt ? (
            <SquareCtaAlt to={to} style={{ width: "100%" }}>
              Choose
            </SquareCtaAlt>
          ) : (
            <SquareCta to={to} style={{ width: "100%" }}>
              Choose
            </SquareCta>
          ))}
      </StyledPanelContainer>
    </StyledThinInfoPanel>
  );
};

export default PackageOption;
