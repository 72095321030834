import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";

import { SectionHeading } from "_components/atoms/common/styles/SectionHeadings.styled";
import PackageOption from "_components/molecules/displays/PackageOption";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

const dividers = ["Your Earnings"];

const basic = [
  [
    [
      "Earnings Per Edit",
      "$0.80",
      [
        "Per minute of running time",
        "Equivalent to a $10 gig on Fiverr (10mins)",
      ],
    ],
    ["Earnings Per Thumbnail", "$4.00", ["Equivalent to a $5 gig on Fiverr"]],
  ],
];
const premium = [
  [
    [
      "Earnings Per Edit",
      "$1.20",
      [
        "Per minute of running time",
        "Equivalent to a $15 gig on Fiverr (10mins)",
      ],
    ],
    ["Earnings Per Thumbnail", "$4.00", ["Equivalent to a $5 gig on Fiverr"]],
  ],
];
const pro = [
  [
    [
      "Earnings Per Edit",
      "$1.60",
      [
        "Per minute of running time",
        "Equivalent to a $20 gig on Fiverr (10mins)",
      ],
    ],
    ["Earnings Per Thumbnail", "$4.00", ["Equivalent to a $5 gig on Fiverr"]],
  ],
];

const ProductionPlans = () => {
  return (
    <StyledDualXStrip $only="pull">
      <SectionHeading
        title="Compensation"
        description="View how much you earn based on which pricing plan your client is on"
      />

      <StyledCenteredArea>
        <p
          style={{
            padding: "20px",
            border: "dotted 2px var(--sub-info-alt)",
            maxWidth: "550px",
            textAlign: "center",
          }}
        >
          Note that "Equivalent to a $10 gig on Fiverr (10mins)" ($1.00 per
          minute of running time), is referring to how Fiverr takes a 20% cut
          from your earnings, leaving you with "$8.00" ($0.80 per minute of
          running time).
          <br />
          <br />
          You then earn "$1.00 per minute of running time" because the edited
          video must be "10mins" long. So, $15 (10mins) would be $1.50 per
          minute of running time, and so on.
          <br />
          <br />
          So we are showing your <b>raw 100% earnings</b>, which are equivalent
          to what you would price your Fiverr gig at!
        </p>
      </StyledCenteredArea>

      <StyledStandardSpacer $paddingTop="50px" />

      <StyledSbs $align="start" $gap="min(50px, 5%)">
        <PackageOption
          title="Basic"
          description="Budget Friendly"
          dividers={dividers}
          entries={basic}
          alt
        />
        <PackageOption
          $highlighted
          title="Premium"
          description="Most Popular"
          dividers={dividers}
          entries={premium}
        />
        <PackageOption
          title="Pro"
          description="Best Value"
          dividers={dividers}
          entries={pro}
          alt
        />
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default ProductionPlans;
