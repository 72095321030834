import { StyledFlexbox } from "components/styles/SideBySide.styled";
import { StyledSwitchFlip } from "./styles/ToggleButtons.styled";
import { HoverSpan } from "../inline/SpanElements";
import linked from "_components/function/modifiers/linked";

const LinkedHoverSpan = linked(HoverSpan);

const SwitchFlip = ({
  $text,
  to,
  enabled,
  setEnabled,
  children: title,
}: {
  $text: string;
  to?: string;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  children: string;
}) => {
  return (
    <StyledFlexbox $align="center" $justify="center" $gap="12px">
      <LinkedHoverSpan
        $align="left"
        $col="var(--sub-info-alt)"
        $text={$text}
        to={to}
      >
        {title}
      </LinkedHoverSpan>
      <StyledSwitchFlip
        $enabled={enabled}
        onClick={() => setEnabled(!enabled)}
      />
    </StyledFlexbox>
  );
};

export default SwitchFlip;
