import React from "react";

import SettingBlock from "../SettingBlock";

import ConnectedAccounts from "../blocks/ConnectedAccounts";

const ProductionSettings = ({ dbName, columnName }) => {
  return (
    <>
      <SettingBlock
        $height="180px"
        $padding="0"
        $flex
        title="Connected Accounts"
      >
        <ConnectedAccounts dbName={dbName} columnName={columnName} />
      </SettingBlock>
    </>
  );
};

export default ProductionSettings;
