import React, { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";

import ProfileCard from "./card/ProfileCard";

import {
  StyledSidebar,
  StyledSidebarTab,
} from "_components/molecules/displays/styles/Sidebar.styled";
import PartnerTabs from "./settings/collections/PartnerTabs";

import AccountSettings from "./settings/blocks/AccountSettings";
import PaymentSettings from "./settings/blocks/PaymentSettings";

export const StyledProfile = styled.div`
  padding: 200px 50px;

  display: flex;
  gap: 60px;

  position: relative;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Profile = () => {
  const userAccountType = ReactSession.get("useraccounttype");
  const { search } = useLocation();

  const [activeTab, setActiveTab] = useState("account");

  const tabJumpObserver = () => {
    const queryParams = new URLSearchParams(search);
    const tab = queryParams.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  };

  useEffect(tabJumpObserver, []);

  return (
    <StyledDualXStrip>
      <StyledProfile>
        <ProfileCard />

        {activeTab == "account" && <AccountSettings />}
        {activeTab == "payment" && <PaymentSettings />}

        <StyledSidebar $minWidth="200px">
          <h2>Settings</h2>

          <StyledSidebarTab
            $isActive={activeTab == "account"}
            onClick={() => setActiveTab("account")}
          >
            Account
          </StyledSidebarTab>

          <PartnerTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        </StyledSidebar>
      </StyledProfile>
    </StyledDualXStrip>
  );
};

export default Profile;
