import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";

import { SectionHeading } from "_components/atoms/common/styles/SectionHeadings.styled";
import PackageOption from "_components/molecules/displays/PackageOption";

const InlinePricing = () => {
  return (
    <StyledDualXStrip $only="pull">
      <SectionHeading
        title="Pricing"
        description="View the tiered package options you can change between as a Partner in our company"
      />

      <StyledStandardSpacer $paddingTop="50px" />
      <StyledSbs $align="start" $gap="min(50px, 5%)">
        <PackageOption
          title="Basic"
          description="Budget Friendly"
          to="/pricing"
          alt
        />
        <PackageOption
          $highlighted
          title="Premium"
          description="Most Popular"
          to="/pricing"
        />
        <PackageOption title="Pro" description="Best Value" to="/pricing" alt />
      </StyledSbs>
    </StyledDualXStrip>
  );
};

export default InlinePricing;
