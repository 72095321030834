import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";
import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import {
  BrandComparisonPrimary,
  BrandComparisonSecondary,
} from "components/objects/general/BrandComparisons";
import ComparisonContainer from "_components/molecules/containers/common/ComparisonContainer";

import LoneCtaSection from "components/sections/common/LoneCtaSection";
import ProductionRotCircle from "_components/features/alt-join/production/RotCircle";
import ProductionPlans from "_components/features/checkout/ProductionPlans";
import ProdSystemsOverview from "_components/features/alt-join/production/ProdSystemsOverview";

const JoinProduction = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/barrumble-gfx.jpg">
        <h1>Join the Production Teams</h1>
        <p>
          All information regarding video editors & thumbnails artists
          interested in joining us
        </p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <ProductionRotCircle />

      <StyledStandardSpacer />
      <ComparisonContainer title="Brand Comparison">
        <BrandComparisonPrimary />
        <BrandComparisonSecondary />
      </ComparisonContainer>

      <StyledStandardSpacer $paddingTop="400px" />
      <ProdSystemsOverview />

      <StyledStandardSpacer $paddingTop="400px" />
      <ProductionPlans />

      <StyledStandardSpacer $paddingTop="400px" />
      <LoneCtaSection to="/join/production/access-key">
        Join Production
      </LoneCtaSection>
    </StyledStandardEndSection>
  );
};

export default JoinProduction;
