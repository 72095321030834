import { useState, useContext } from "react";

import ContentHeader from "components/sections/ContentHeader";
import ContentSections from "components/sections/ContentSections";

import { StickyCta } from "components/CtaElements";
import StickyBackToTop from "components/objects/navigation/StickyBackToTop";

import { StickyCtaContext } from "components/contexts/StickyCtaContext";

import { AccountContext } from "components/contexts/AccountContext";

const Content = () => {
  const [isStickiesEnabled, setIsStickiesEnabled] = useState(false);
  const [loggedIn, setLoggedIn] = useContext(AccountContext);

  return (
    <>
      <StickyBackToTop $isEnabled={isStickiesEnabled} to="content-header" />

      <StickyCtaContext.Provider
        value={[isStickiesEnabled, setIsStickiesEnabled]}
      >
        <ContentHeader name="content-header" />
        <ContentSections />
      </StickyCtaContext.Provider>
    </>
  );
};

export default Content;
