import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";

import FormPage from "components/canvas/FormPage";

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledFormOptions,
  StyledGroupWrapper,
} from "_components/features/form/styles/FormStructs.styled";

import FormErrorMessage from "_components/features/form/elements/FormErrorMessage";
import FormOption from "_components/features/form/FormOption";
import FormItem from "_components/features/form/FormItem";
import FormCta from "_components/features/form/FormCta";

import { ENDPOINT } from "js/data/constants";
import { getErrorMessage } from "js/data/formErrorMessages";
import { HoverSpan } from "_components/atoms/inline/SpanElements";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

function Join() {
  const [selectedOption, setSelectedOption] = useState("Partner");
  const [errorMessages, setErrorMessages] = useState([]);
  const [canSend, setCanSend] = useState(true);

  const navigate = useNavigate();
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);

    const obj = {
      name: formRef.current.username.value,
      email: formRef.current.email.value,
      username: formRef.current.username.value,
      password: formRef.current.password.value,
      rblxHandle: formRef.current.rblxHandle.value,
      accountType: selectedOption,
      target: formRef.current.target.value,
      via: ReactSession.get("via"),
    };

    if (!canSend) {
      setErrorMessages(["Form send in progress.."]);
      return;
    }

    setCanSend(false);

    axios
      .post(`${ENDPOINT}/api/auth/user/signup`, obj)
      .then((res) => {
        let { hash, email } = res.data;
        if (!hash || !email) {
          throw new Error(
            "Hash or Email missing from signup response on parsing!"
          );
        }
        console.log("successful client signup!");
        // TODO: Change back to /join/verify when auth is added back for clients
        navigate(`/login`);
      })
      .catch((error) => {
        setErrorMessages([getErrorMessage(error)]);
        setCanSend(true);
        console.error(error);
      });
  };

  return (
    <FormPage>
      <ParamTabSet setTab={setSelectedOption} query="type" />
      <StyledFormContainer
        $shadowed
        ref={formRef}
        onSubmit={(e) => handleSubmit(e)}
      >
        <StyledFormTitle $bottom="10px">Join as a Youtuber</StyledFormTitle>
        <FormItem
          $label="Email"
          placeholder="example@gmail.com"
          type="email"
          name="email"
          maxLength={320}
          required
          autoFocus
        />
        <FormItem
          $label="Username"
          placeholder="username"
          name="username"
          maxLength={26}
          required
        />
        <FormItem
          $label="Password"
          placeholder="password"
          type="password"
          name="password"
          maxLength={50}
          required
        />

        <FormItem
          $label="Roblox Username"
          style={{
            gridColumn: selectedOption === "Partner" ? "1 / 2" : null,
          }}
          placeholder="username"
          name="rblxHandle"
          maxLength={20}
          required
        />
        {selectedOption === "Partner" && (
          <FormItem
            $label="Target No. Videos (weekly)"
            style={{ gridColumn: "2 / 3" }}
            placeholder="2-14 videos per week"
            type="number"
            name="target"
            max={14}
            min={2}
            required
          />
        )}
        <StyledFormOptions>
          <FormOption
            value="Partner"
            name="accountType"
            defaultChecked
            checked={selectedOption == "Partner"}
            setSelectedOption={setSelectedOption}
          >
            Join as a{" "}
            <HoverSpan
              style={{ textAlign: "left" }}
              $text="You want to purchase edit/thumbnail services & gain access to all rewards"
            >
              Partner
            </HoverSpan>
          </FormOption>
          <FormOption
            value="Affiliate"
            name="accountType"
            checked={selectedOption == "Affiliate"}
            setSelectedOption={setSelectedOption}
            style={{ position: "relative" }}
          >
            Join as an{" "}
            <HoverSpan
              style={{ textAlign: "left" }}
              $text="You only advertise our brand & are compensated for it"
            >
              Affiliate
            </HoverSpan>
          </FormOption>
        </StyledFormOptions>

        {errorMessages.length > 0 && (
          <StyledGroupWrapper>
            {errorMessages.map((message, index) => (
              <FormErrorMessage message={message} key={index} />
            ))}
          </StyledGroupWrapper>
        )}

        {/* Provide spacing by activating gap of grid */}
        <div></div>
        <FormCta note="Certain info can be changed after signing up, via settings">
          {canSend ? "Create Account" : "Sending.."}
        </FormCta>
      </StyledFormContainer>
    </FormPage>
  );
}

export default Join;
