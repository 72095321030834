import { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactSession } from "react-client-session";

import Footer from "components/sections/Footer";
import Navbar from "components/objects/navigation/Navbar";

import GlobalStyles from "components/styles/Global";
import ScrollToTop from "_components/function/handlers/ScrollToTop";
import AuthLoggedIn from "_components/function/handlers/AuthLoggedIn";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

import { QueryContext } from "components/contexts/QueryContext";
import { AccountContext } from "components/contexts/AccountContext";

import Home from "pages/index";
import Content from "pages/Content";
import JoinProduction from "pages/alt/JoinProduction";
import ForNonRbx from "pages/alt/ForNonRbx";
import AffiliateProgram from "pages/alt/AffiliateProgram";

import Pricing from "pages/Pricing";
import GameBenefits from "pages/benefits/GameBenefits";
import ProductionBenefits from "pages/benefits/ProductionBenefits";
import Documentation from "pages/Documentation";
import RulesAndGuidelines from "pages/Documentation/RulesAndGuidelines";
import TermsOfService from "pages/Documentation/TermsOfService";
import PrivacyPolicy from "pages/Documentation/PrivacyPolicy";

import Join from "pages/Join";
import JoinReturn from "pages/Join/Return";
import JoinVerify from "pages/Join/Verify";
import JoinVerifyReturn from "pages/Join/Verify/Return";
import Login from "pages/Login";
import JoinProductionAccessKey from "pages/Join/production/AccessKey";

import GameManager from "pages/game/Manager";
import OwnershipBooster from "pages/game/Booster";

import Chat from "pages/comm/Chat";
import FileManager from "pages/comm/FileManager";
import TeamView from "pages/comm/TeamView";
import Scheduler from "pages/comm/Scheduler";
import ContactUs from "pages/comm/ContactUs";
import Profile from "pages/Profile";
import Logout from "pages/Logout";

import AdminPanel from "pages/admin/Panel";
import ApAnalytics from "pages/admin/Analytics";
import ApManage from "pages/admin/Manage";
import ApReview from "pages/admin/Review";

import Checkout from "pages/Checkout";
import CheckoutReturn from "pages/Checkout/Return";

import { FriendsContext } from "_components/function/contexts/FriendsContext";

import queryChangeHandler from "js/queryChangeHandler";
import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

function App() {
  const [queryWrap, setQueryWrap] = useState({});
  let wrapQuery = window.matchMedia("(max-width: 1370px)");
  useEffect(() => queryChangeHandler(wrapQuery, setQueryWrap), []);

  const queries = {
    queryWrap: queryWrap,
  };

  const [friends, setFriends] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [storageLoaded, setStorageLoaded] = useState(false);

  const loadFriendships = async () => {
    if (!loggedIn) {
      setFriends([]);
      return;
    }

    axios
      .get(`${ENDPOINT}/api/comm/friendships`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => setFriends(res.data))
      .catch(console.error);
  };

  // useEffect must be () => { load() }, not () => load(). useEffect can't be async
  useEffect(() => {
    loadFriendships();
  }, [loggedIn]);

  // State stays on refresh, react state doesn't, so just re-apply it if state still exists (haven't logged out to remove it)
  useEffect(() => {
    ReactSession.setStoreType("localStorage");
    if (ReactSession.get("useruid") != undefined) {
      setLoggedIn(true);
    }
    setStorageLoaded(true);
  }, []);

  return (
    <div className="App">
      <GlobalStyles />
      <AccountContext.Provider value={[loggedIn, setLoggedIn]}>
        <QueryContext.Provider value={queries}>
          <FriendsContext.Provider value={friends}>
            <BrowserRouter>
              {storageLoaded && (
                <ParamTabSet
                  $autoReset
                  query="via"
                  setTab={(via) => ReactSession.set("via", via)}
                />
              )}
              <ScrollToTop />
              <AuthLoggedIn />
              <Navbar />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/content" element={<Content />} />
                <Route path="/production" element={<JoinProduction />} />
                <Route path="/youtuber" element={<ForNonRbx />} />
                <Route path="/affiliate" element={<AffiliateProgram />} />

                <Route exact path="/pricing" element={<Pricing />} />
                <Route exact path="/benefits/game" element={<GameBenefits />} />
                <Route
                  exact
                  path="/benefits/production"
                  element={<ProductionBenefits />}
                />
                <Route exact path="/contact" element={<ContactUs />} />
                <Route exact path="/docs" element={<Documentation />} />
                <Route
                  exact
                  path="/docs/rules-and-guidelines"
                  element={<RulesAndGuidelines />}
                />
                <Route
                  exact
                  path="/docs/terms-of-service"
                  element={<TermsOfService />}
                />
                <Route
                  exact
                  path="/docs/privacy-policy"
                  element={<PrivacyPolicy />}
                />

                <Route exact path="/join" element={<Join />} />
                <Route exact path="/join/return" element={<JoinReturn />} />
                <Route exact path="/join/verify" element={<JoinVerify />} />
                <Route
                  exact
                  path="/join/verify/return"
                  element={<JoinVerifyReturn />}
                />
                <Route exact path="/login" element={<Login />} />
                <Route
                  exact
                  path="/join/production/access-key"
                  element={<JoinProductionAccessKey />}
                />

                <Route exact path="/game/manager" element={<GameManager />} />
                <Route
                  exact
                  path="/game/booster"
                  element={<OwnershipBooster />}
                />

                <Route exact path="/chat" element={<Chat />} />
                <Route exact path="/file" element={<FileManager />} />
                <Route exact path="/team" element={<TeamView />} />
                <Route exact path="/scheduler" element={<Scheduler />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/logout" element={<Logout />} />

                <Route exact path="/admin/panel" element={<AdminPanel />} />
                <Route
                  exact
                  path="/admin/analytics"
                  element={<ApAnalytics />}
                />
                <Route exact path="/admin/manage" element={<ApManage />} />
                <Route exact path="/admin/review" element={<ApReview />} />

                <Route exact path="/checkout" element={<Checkout />} />
                <Route
                  exact
                  path="/checkout/return"
                  element={<CheckoutReturn />}
                />
              </Routes>
              <Footer />
            </BrowserRouter>
          </FriendsContext.Provider>
        </QueryContext.Provider>
      </AccountContext.Provider>
    </div>
  );
}

export default App;
