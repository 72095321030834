import { useContext } from "react";
import { ReactSession } from "react-client-session";

import { BlendImg } from "../BlendElements";
import { StyledAspectRatioWrapper } from "../styles/AspectRatioContainer.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import Games from "../sections/Games";
import Edits from "../sections/Edits";
import Thumbnails from "../sections/Thumbnails";

import { AccountContext } from "components/contexts/AccountContext";
import RedirectBlockSection from "_components/molecules/containers/common/RedirectBlockSection";

const ContentSections = () => {
  const [loggedIn] = useContext(AccountContext);

  return (
    <section
      style={{
        position: "relative",
        // Index is set higher than all other sections so 'GameCardOverlay' is on top of everyting
        zIndex: "1",
      }}
    >
      <StyledAspectRatioWrapper $maxHeight="475px" style={{ zIndex: 1 }}>
        <BlendImg
          $aspectRatio="4:1"
          src="/images/layered-peaks-haikei-content.png"
        />
      </StyledAspectRatioWrapper>

      <StyledStandardSpacer />

      {!loggedIn || ReactSession.get("useraccounttype") === "Production" ? (
        <>
          <RedirectBlockSection
            $primary="var(--action-primary)"
            $maxWidth="420px"
            heading="Edits & Thumbnails"
            subHeading="View examples of edits and thumbnails made for previous clients by simply creating a free account now!"
            to="/join"
            ctaText="Gain Access"
          />
        </>
      ) : (
        <>
          <Edits />
          <StyledStandardSpacer />
          <Thumbnails />
        </>
      )}

      <StyledStandardSpacer />
      <Games />
    </section>
  );
};

export default ContentSections;
