import React from "react";

import { StyledFaqList } from "_components/themes/faq/FaqElements";
import {
  StyledHeadingStrip,
  StyledStripWrapper,
} from "components/styles/StripBanner.styled";
import { StyledOverlay } from "components/styles/Overlay.styled";

import StripBanner from "components/StripBanner";
import { LinkSpan, ScrollElement } from "_components/atoms/inline/SpanElements";
import FaqItem from "_components/themes/faq/FaqItem";

function Faq({ name }) {
  return (
    <section name={name}>
      <StyledOverlay
        $from="var(--main-background)"
        $to="var(--action-primary-dimmed)"
        $opacity="0.7"
      >
        <StripBanner
          $opacity="0.8"
          $pos="60%"
          src="/images/listen-gfx-2.jpg"
          style={{ zIndex: -1 }}
        >
          <StyledStripWrapper>
            <StyledHeadingStrip>Frequently Asked Questions</StyledHeadingStrip>
          </StyledStripWrapper>
        </StripBanner>

        <StyledFaqList style={{ paddingBottom: "400px" }}>
          <FaqItem>
            <p className="header">
              Can I get a refund on purchases of video edits or thumbnails?
            </p>
            <p className="content">
              Yes! If you purchased an edit or thumbnail service, and then
              requested your editor/artist to provide that service, it will use
              up that purchased service in your account and they will start
              fulfilling the request. If the editor/artist fails to provide the
              product within your set number of days allocated, then the request
              will fail and you will receive a refund of that edit/thumbnail
              service back to your account along with an email confirming
              cancellation of the request and the refunded service back into
              your account.
            </p>
            <img className="icon" src="/images/faq-arrow.png" />
          </FaqItem>

          <FaqItem>
            <p className="header">
              In simple terms, what do I get and what do I pay for it?
            </p>
            <div className="content">
              <p>
                Our business model may seem complicated or daunting but in
                reality it’s extremely simple.
              </p>
              <p>
                <b>You receive</b> a personal team of editors and/or thumbnail
                artists to help you produce your youtube videos according to
                your schedule and style. That is what you are paying for, a
                professional, dedicated and collaborative team which surpasses
                any collection of freelancers, and for the same price! See our
                pricing <LinkSpan to="/pricing">here</LinkSpan>.
              </p>
              <p>
                <b>You also receive, as a free gift</b>,{" "}
                <LinkSpan to="/benefits/game">free advertising</LinkSpan> for
                your channel via our Roblox Games, and a{" "}
                <LinkSpan to="/benefits/game">percentage ownership</LinkSpan> of
                all profits from many games at once, which you can select
                instantly upon signing up from the quickly growing list of our
                games which we manage.
              </p>
              <p>
                This is our philosophy and what outweights even all the other
                benefits we offer through our company. This is what massively
                sets us apart from you choosing normal freelancer
                editors/artists over us. We offer an improved version of what
                they offer, and we throw in free marketing and earning potential
                for your channel.
              </p>
              <p style={{ fontWeight: "bold" }}>
                We are, in simple terms, the only all-in-one, full, pre-bulit
                team you need to manage, monitize, and scale your Roblox Youtube
                channel. And we’re the only ones who do it!
              </p>
            </div>
            <img className="icon" src="/images/faq-arrow.png" />
          </FaqItem>

          <FaqItem>
            <p className="header">
              What is planned for PUSHXPULL in order to constantly improve and
              scale it’s value to the Roblox Community?
            </p>
            <div className="content">
              <ol>
                <li>
                  <b>
                    Constantly improve the skills of our production staff, your
                    team
                  </b>
                  <br />
                  <p>
                    This will be done through regular training, innovative
                    ideas, and inclusion of individuals with varying skillsets
                    to help us effectively stay ahead of trends, production
                    quality, marketing techniques and keeping constant growth
                    coming to your channel.
                  </p>
                </li>
                <li>
                  <b>Produce more games, faster, and better than before</b>
                  <br />
                  <p>
                    We are constantly building new teams of game developers of
                    better quality to work on new game ideas we have decided to
                    publish. This process shall continue to get faster and of
                    higher quality, gaurenteeing that we will bring in more game
                    profits across all of our studios over time. This is great
                    news for you, because you get a piece of that pie. You do no
                    work on these games, and yet you will still reap their{" "}
                    <LinkSpan to="/benefits/game">profits</LinkSpan> and{" "}
                    <LinkSpan to="/benefits/game">advertising</LinkSpan>{" "}
                    potential!
                  </p>
                </li>
                <li>
                  <b>
                    Expand on the benefits offered through our website, trying
                    to constantly give you more value which we can provide
                  </b>
                  <br />
                  <p>
                    Your experience with your company is our highest priority.
                    This is why we don’t want to stay the same for too long. We
                    get bored easily. That’s why we have a team of innovators
                    who actively analyse markets and constantly engineer new
                    solutions and ideas for how we can scale our company to be
                    even more valuable than it already is, offering you new and
                    greater potential. In other words,{" "}
                    <b>improving your experience in our company!</b>
                  </p>
                </li>
              </ol>
            </div>
            <img className="icon" src="/images/faq-arrow.png" />
          </FaqItem>

          <FaqItem>
            <p className="header">
              What does <i>‘PUSHXPULL’</i> mean?
            </p>
            <div className="content">
              <p>
                <i>”For every push there is a pull”</i>. This is our motto. It
                is also what <i>‘PUSHXPULL’</i> means. For every benefit you{" "}
                <i>push</i> our way (e.g. paying for our production services),
                we <i>pull</i> benefits of at least twice the value you provided
                us, back in your direction, empahsised by the 2 <i>‘L’</i>s in
                the word pull. (e.g. we provide a full production team, and
                advertise your channel, and give you direct profits from our
                games)
              </p>
              <p>
                The <i>'X'</i> in-between <i>'PUSH'</i> and <i>'PULL'</i> helps
                emphasise our unity regarding this dynamic, denoted by the two
                crossing lines of the letter <i>'X'</i>. Where you as our
                partner and us as your team, isn't a formal and blunt
                relationship. But instead, should become a personal and
                connected group of real, skilled, ambitious people who can help
                eachother grow in skill and success!
              </p>
            </div>
            <img className="icon" src="/images/faq-arrow.png" />
          </FaqItem>
        </StyledFaqList>
      </StyledOverlay>
    </section>
  );
}

export default Faq;
