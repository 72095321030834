import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import KeyInformation from "_components/features/game/booster/KeyInformation";
import OwnershipBoosterSystem from "_components/features/game/booster/OwnershipBoosterSystem";
import RedirectBlockSection from "_components/molecules/containers/common/RedirectBlockSection";

const OwnershipBooster = () => {
  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="50%" src="/images/astral-gfx.jpg">
        <h1>Ownership Booster</h1>
        <p>Increase your profile stats and game ownership percentages</p>
      </TopHeadingBanner>

      <StyledStandardSpacer $paddingTop="50px" />
      <KeyInformation />

      <StyledStandardSpacer $paddingTop="400px" />
      <RedirectBlockSection
        $primary="var(--contrast-important)"
        heading="View the Rules & Guidelines"
        subHeading="Check out the rules & guidelines before you use the Ownership Booster"
        to="/docs/rules-and-guidelines?tab=boosting"
        ctaText="View"
      />

      <StyledStandardSpacer $paddingTop="400px" />
      <OwnershipBoosterSystem />
    </StyledStandardEndSection>
  );
};

export default OwnershipBooster;
