import { useState } from "react";
import styled from "styled-components";

import { StyledHoverFlagBtn } from "./styles/IconButtons.styled";
import { StyledFlexbox } from "components/styles/SideBySide.styled";

type CopyToClipboard = {
  link: string;
};

const StyledLink = styled.div`
  color: var(--action-primary);
  font-weight: bold;
  cursor: pointer;
  text-align: center;

  width: 80%;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
  }
`;

const CopyToClipboard = ({ link }: CopyToClipboard) => {
  const [text, setText] = useState("Copy");

  function copyLinkToClipboard() {
    if (text === "Copied!") return;
    navigator.clipboard.writeText(link);
    setText("Copied!");
    setTimeout(() => {
      setText("Copy");
    }, 500);
  }

  return (
    <StyledHoverFlagBtn
      $offset="-15%"
      $enabled={true}
      style={{ height: "auto", width: "100%" }}
    >
      <StyledFlexbox $align="center">
        <div className="hover-flag">{text}</div>
        <StyledLink onClick={copyLinkToClipboard}>{link}</StyledLink>
      </StyledFlexbox>
    </StyledHoverFlagBtn>
  );
};

export default CopyToClipboard;
