import React, { useState, useContext } from "react";
import { styled, css } from "styled-components";

import FlagHeading from "../common/FlagHeading";

import { GalleryContext } from "../../contexts/GalleryContext";

import { ScrollerBtn, TramScroller } from "./Scroller";

const StyledGalleryContainer = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  position: relative;
  grid-template-rows: 13% 1fr 13%;
  grid-template-columns: 7% 15% 3% 1fr 20%;

  @media screen and (max-width: 1370px) {
    grid-template-rows: 13% 1fr 3% 20%;
    grid-template-columns: 1fr;
  }
`;

const StyledContentDisplay = css`
  grid-area: 2 / 4 / 3 / 5;
  width: 100%;

  @media screen and (max-width: 1370px) {
    grid-area: 2 / 1 / 3 / -1;
  }
`;

const StyledIframeContent = styled.iframe`
  ${StyledContentDisplay};
  width: 100%;
  height: 105%;
`;

const StyledImgContent = styled.img`
  ${StyledContentDisplay};
`;

const StyledSideContent = styled.div`
  grid-area: 1 / 2 / 4 / 3;
  background-color: #12121d;

  display: grid;
  grid-template-rows: 13% 1fr 13%;
  justify-items: center;

  padding: 10% 2%;

  @media screen and (max-width: 1370px) {
    grid-area: -1 / 1 / -2 / -1;
    padding: 2% 10%;

    grid-template-rows: 1fr;
    grid-template-columns: 13% 1fr 13%;
  }
`;

const Gallery = ({ title, ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = useContext(GalleryContext);

  const currentItem = items[currentIndex];

  return (
    <StyledGalleryContainer {...props}>
      {currentItem.type === "thumbnail" ? (
        <StyledImgContent src={currentItem.original} />
      ) : (
        <StyledIframeContent
          frameBorder="0"
          src={currentItem.original}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media;
        gyroscope; picture-in-picture; web-share"
        />
      )}
      <StyledSideContent>
        <ScrollerBtn
          src="/images/arrow_up.png"
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          increment={-1}
          maxIndex={items.length}
        />
        <TramScroller
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
        <ScrollerBtn
          src="/images/arrow_down.png"
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
          increment={1}
          maxIndex={items.length}
        />
      </StyledSideContent>
    </StyledGalleryContainer>
  );
};

export default Gallery;
