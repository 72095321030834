import React, { useRef, useContext } from "react";

import { StyledGfxCircle } from "../../_components/molecules/displays/circle/GfxCircle.styled";
import {
  StyledAspectRatioContainer,
  StyledAspectRatioWrapper,
} from "../styles/AspectRatioContainer.styled";
import {
  StyledGlowH1,
  StyledGlowP,
} from "../styles/common/GlowHeaderElements.styled";

import { StickyCtaContext } from "../contexts/StickyCtaContext";

import ResponsiveStickiesHandler from "../handlers/ResponsiveStickiesHandler";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import {
  StyledHeaderContainer,
  StyledOverlayText,
  StyledSoldierImg,
  StyledCircleBackground,
} from "_components/features/content/styles/Header.styled";

function ContentHeader({ name }) {
  const contentHeaderRef = useRef(null);
  const [_isStickiesEnabled, setIsStickiesEnabled] =
    useContext(StickyCtaContext);

  let mobileQuery = window.matchMedia("(max-width: 800px)");

  return (
    <section
      style={{ position: "relative" }}
      name={name}
      ref={contentHeaderRef}
    >
      <ResponsiveStickiesHandler
        windowQuery={mobileQuery}
        setIsStickiesEnabled={setIsStickiesEnabled}
        refForObserver={contentHeaderRef}
      />

      <StyledStandardSpacer $paddingTop="150px" />

      <StyledHeaderContainer>
        <StyledOverlayText>
          <h1>View some of the content from our teams</h1>
          <p>
            See edits & thumbnails produced by our Production Teams.
            <br />
            See games published and being developed by our Development Teams.
          </p>
        </StyledOverlayText>

        <StyledAspectRatioWrapper $maxHeight="1000px" $minHeight="400px">
          <StyledAspectRatioContainer $aspectRatio="2:1">
            <StyledGfxCircle
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: "50%",
              }}
            >
              <StyledCircleBackground className="center">
                <StyledSoldierImg src="/images/soldiers-salute.png" />
              </StyledCircleBackground>
            </StyledGfxCircle>
          </StyledAspectRatioContainer>
        </StyledAspectRatioWrapper>
      </StyledHeaderContainer>
    </section>
  );
}

export default ContentHeader;
