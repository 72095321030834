import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import {
  StyledFormContainer,
  StyledFormOptions,
  StyledFormTitle,
  StyledFormP,
  StyledGroupWrapper,
} from "_components/features/form/styles/FormStructs.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { SectionHeading } from "_components/atoms/common/styles/SectionHeadings.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import FormCta from "_components/features/form/FormCta";
import FormSelect from "_components/features/form/elements/FormSelect";
import FormOption from "_components/features/form/FormOption";
import FormItem from "_components/features/form/FormItem";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { ENDPOINT } from "js/data/constants";
import gameDatas from "js/data/gameDatas";
import getAccessToken from "js/features/auth/getAccessToken";

const OwnershipBoosterSystem = () => {
  const formRef = useRef(null);

  const [selectedGame, setSelectedGame] = useState("");
  const [selectedOption, setSelectedOption] = useState(false);
  const [userSelectedGames, setUserSelectedGames] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      url: formRef.current.url.value,
      method: formRef.current.method.value,
      otherName: formRef.current.other_name?.value,
      gameName: selectedGame,
    };

    axios
      .post(`${ENDPOINT}/api/booster/requests`, formData, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then(console.log)
      .catch(console.error);
  };

  const loadGamesList = async () => {
    setIsLoading(true);
    axios
      .get(`${ENDPOINT}/api/game/list-user`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const gamesList = res.data.selectedGames.map(
          (id) => gameDatas.find((data) => data.id == id)?.game
        );
        setUserSelectedGames(gamesList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    loadGamesList();
  }, []);

  return (
    <StyledDualXStrip $pad>
      <SectionHeading title="Increase Your Game Ownership" />

      <StyledStandardSpacer $paddingTop="50px" />

      <StyledCenteredArea>
        <StyledFormContainer
          $bg="light"
          $bordered
          $shadowed
          ref={formRef}
          onSubmit={handleSubmit}
          style={{
            width: "min(70%, 1100px)",
            borderRadius: "5px",
          }}
        >
          <StyledFormTitle $bottom="10px">
            Ownership Boost Request Form
          </StyledFormTitle>

          <StyledGroupWrapper>
            <StyledFormP>
              Enter the URL containing the advertising content
            </StyledFormP>
            <FormItem
              $label="URL"
              placeholder="https://www.pushxpull.co"
              name="url"
              pattern="https?://.+"
              maxLength={300}
              required
            />
          </StyledGroupWrapper>

          <StyledGroupWrapper>
            <StyledFormP>Select your method of advertising</StyledFormP>

            <StyledFormOptions>
              <FormOption
                value="post"
                name="method"
                setSelectedOption={setSelectedOption}
                required
              >
                Social Media Post
              </FormOption>
              <FormOption
                value="videoPlug"
                name="method"
                setSelectedOption={setSelectedOption}
              >
                Video Plug
              </FormOption>
              <FormOption
                value="gameplay"
                name="method"
                setSelectedOption={setSelectedOption}
              >
                Dedicated Video
              </FormOption>
              <FormOption
                value="descPlug"
                name="method"
                setSelectedOption={setSelectedOption}
              >
                Comment/Description Plug
              </FormOption>
            </StyledFormOptions>
          </StyledGroupWrapper>

          <StyledGroupWrapper>
            <StyledFormP>Select the game you advertised</StyledFormP>

            <LoadingStatus
              status={isLoading ? 0 : userSelectedGames.length > 0}
              style={{ gridColumn: "1 / -1", textAlign: "center" }}
            />

            <FormSelect
              setSelection={setSelectedGame}
              optionsList={userSelectedGames}
              placeholder="-- SELECT A GAME --"
            />
          </StyledGroupWrapper>

          {selectedGame != "" && <FormCta>Confirm & Send</FormCta>}
        </StyledFormContainer>
      </StyledCenteredArea>
    </StyledDualXStrip>
  );
};

export default OwnershipBoosterSystem;
