import { LinkSpan } from "_components/atoms/inline/SpanElements";
import { StyledDocSection } from "_components/features/docs/main/Documentation.styled";

const Staff = () => {
  return (
    <>
      <StyledDocSection>
        <p className="title">Payment</p>
        <p>
          As a staff member, either <i>editor</i> or <i>thumbnail artist</i>,
          you get paid all of the money you are due, every <b>Sunday</b> at any
          time between <b>7AM GMT+1</b> and <b>11PM GMT+1</b>. We send the money
          directly to your <b>PayPal</b> account, which you can link to your
          account in <LinkSpan to="/profile?tab=payment">settings</LinkSpan>. If
          your payment is not received within that time, please contact us via
          either emailing 'contact@pushxpull.co' or chatting with us via the{" "}
          <LinkSpan to="/chat?user=admin">chat</LinkSpan>. Make sure the PayPal
          (or other payment type) account you link is correct, as we cannot get
          your money back if it is sent to the wrong account due to your
          mis-input!
        </p>
      </StyledDocSection>
    </>
  );
};

export default Staff;
