import { styled, css } from "styled-components";

export const StyledFormContainer = styled.form<{
  $rows?: number;
  $scroll?: string;
  $gap?: string;
  $bordered?: string;
  $shadowed?: string;
  $bg?: string;
}>`
  --rows: ${(props) => props.$rows};

  position: relative;
  width: 650px;
  border-radius: 50px;

  align-self: ${(props) => props.$scroll && "start"};

  display: grid;
  grid-row-gap: ${(props) => (props.$gap === "close" ? "20px" : "40px")};
  grid-column-gap: 30px;

  background-color: ${(props) =>
    props.$bg === "light" ? "var(--main-background)" : "var(--main-secondary)"};
  border: ${(props) => props.$bordered && "1px solid var(--action-primary)"};
  filter: ${(props) => props.$shadowed && "drop-shadow(0px 0px 10px #000)"};

  /* grid-template-rows: 1.6fr repeat(var(--rows), 50px) 1fr; */
  grid-template-columns: 1fr 1fr;

  height: auto;
  padding: max(50px, min(3.5%, 70px));

  min-width: 500px;

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    width: 100%;
    min-width: unset;
    background-color: rgb(24, 24, 40, 0.92);
  }
`;

export const StyledGroupWrapper = styled.div`
  position: relative;
  height: 100%;
  grid-column: 1 / -1;

  display: flex;
  flex-direction: column;
  gap: 10px;

  /* Fix height inconsistency with children containers */
`;

export const StyledFormTitle = styled.h1<{
  $noMinHeight?: string;
  $width?: string;
  $bottom?: string;
  $top?: string;
}>`
  position: relative;
  display: block;
  color: white;
  margin: 0;
  padding-bottom: 20px;
  height: 100%;
  min-height: ${(props) => props.$noMinHeight || "80px"};
  width: 100%;
  grid-column: 1 / -1;
  font-size: 2.5rem;
  font-weight: 100;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  &::after {
    content: "";
    height: 2px;
    width: ${(props) => props.$width || "70%"};
    display: block;
    position: absolute;
    background-color: var(--action-primary);

    ${(props) => {
      if (props.$top && !props.$bottom) {
        return css`
          top: ${props.$top};
        `;
      } else if (props.$bottom && !props.$top) {
        return css`
          bottom: ${props.$bottom};
        `;
      } else {
        // Keep default to not break code in other places
        return css`
          top: 4.5rem;
        `;
      }
    }}
  }
`;

export const StyledFormP = styled.p<{ $type?: "error"; $margin?: string }>`
  text-align: center;
  grid-column: 1 / -1;

  ${(props) =>
    props.$type === "error"
      ? css`
          color: var(--exit-main);
          font-weight: bold;
        `
      : css`
          color: white;
        `}

  margin: ${(props) => props.$margin};
`;

export const StyledFormOptions = styled.div`
  display: grid;
  gap: 0 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  grid-column: 1 / -1;
  grid-template-columns: repeat(auto-fill, 220px);
`;

export const StyledFormNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #8989b1;
  grid-column: 1 / -1;
  position: absolute;
  top: 0;
  transform: translateY(calc(-10px - 100%));
  text-align: center;
`;
