import { useContext } from "react";

import StripBanner from "components/StripBanner";
import { CtaInlineText } from "components/CtaElements";

import {
  StyledHeadingStrip,
  StyledStripWrapper,
} from "components/styles/StripBanner.styled";
import { StyledXStrip } from "../XStrip.styled";

import { StyledBlockImg } from "components/styles/Block.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledLineInfoBlock,
  StyledLineInfoBlockH1,
  StyledLineInfoBlockP,
} from "_components/molecules/containers/common/styles/StyledInfoBlock.styled";
import {
  StyledIndexHeader,
  StyledHeadingH2,
  StyledQuestion,
  StyledBorderedP,
} from "components/styles/objects/QaBlock.styled";

import InlinePricing from "_components/features/checkout/InlinePricing";

import { QueryContext } from "components/contexts/QueryContext";

function OurBrand({ name }) {
  const { queryWrap } = useContext(QueryContext);

  const questionsSbsStyle = {
    padding: "0 8% 150px 8%",
    paddingTop: queryWrap.matches ? "0" : "100px",
    justifyContent: "start",
    gap: queryWrap.matches && "0",
    alignItems: queryWrap.matches && "start",
  };

  const answerSbsStyle = {
    paddingBottom: "200px",
  };

  return (
    <section name={name}>
      <StripBanner $pos="50%" src="/images/astral-gfx.jpg">
        <StyledStripWrapper>
          <StyledHeadingStrip>What we offer</StyledHeadingStrip>
        </StyledStripWrapper>
      </StripBanner>

      <div>
        <StyledIndexHeader>
          <h2>#1</h2>
          <h2>🧑‍💻</h2>
        </StyledIndexHeader>
        <StyledHeadingH2>We hand you a full production team</StyledHeadingH2>

        <StyledXStrip $push={2}>
          <StyledSbs $gap="5%" style={questionsSbsStyle}>
            <StyledLineInfoBlockH1
              $noBack
              style={{ color: "var(--contrast-one)" }}
            >
              Q:
            </StyledLineInfoBlockH1>
            <div>
              <StyledQuestion>
                Not enough editors to produce daily videos?
              </StyledQuestion>
              <StyledQuestion>
                Is it difficult to gather multiple editors who charge the same
                price and who can edit in similar styles?
              </StyledQuestion>
            </div>
          </StyledSbs>
        </StyledXStrip>

        <StyledXStrip $pull={2}>
          <StyledSbs $gap="5%" style={answerSbsStyle}>
            <StyledLineInfoBlock>
              <StyledLineInfoBlockH1
                $noBack
                style={{ color: "var(--contrast-two)" }}
              >
                A:
              </StyledLineInfoBlockH1>
              <StyledLineInfoBlockP $white>
                Let us remove the headache for you and let us just hand you a
                full team of any number of artists and/or editors.
              </StyledLineInfoBlockP>
              <StyledLineInfoBlockP $white>
                Your designated production team can adapt to your video style
                through the team training routines conducted between themselves,
                done in order to achieve your desired style accurately.
              </StyledLineInfoBlockP>
              <StyledLineInfoBlockP $white>
                Each editor/artist on the team charges the same amount
                per-video, costing you the same amount for every video.
              </StyledLineInfoBlockP>
              <CtaInlineText to="/benefits/production">
                View a full breakdown of all benefits of our production teams
              </CtaInlineText>
            </StyledLineInfoBlock>
            <StyledBlockImg src="/images/ob-diagram.png" />
          </StyledSbs>
        </StyledXStrip>

        <InlinePricing />
      </div>

      <div style={{ marginTop: "200px" }}>
        <StyledIndexHeader>
          <h2>#2</h2>
          <h2>📈</h2>
        </StyledIndexHeader>
        <StyledHeadingH2>We advertise your channel for free</StyledHeadingH2>

        <StyledXStrip $push={2}>
          <StyledSbs $gap="5%" style={questionsSbsStyle}>
            <StyledLineInfoBlockH1
              $noBack
              style={{ color: "var(--contrast-one)" }}
            >
              Q:
            </StyledLineInfoBlockH1>
            <div>
              <StyledQuestion>
                Do you struggle with marketing your channel to be seen by many
                Robloxians?
              </StyledQuestion>
              <StyledQuestion>
                Struggling to increase your views or subscribers?
              </StyledQuestion>
            </div>
          </StyledSbs>
        </StyledXStrip>

        <StyledXStrip $pull={2}>
          <StyledSbs $gap="15%" style={answerSbsStyle}>
            <StyledLineInfoBlock>
              <StyledLineInfoBlockH1
                $noBack
                style={{ color: "var(--contrast-two)" }}
              >
                A:
              </StyledLineInfoBlockH1>
              <StyledLineInfoBlockP $white>
                By joining us, you become a partner. Through our constantly
                increasing game studios and games, we will advertise your
                channel and your internet personality across these games for
                free. Using our developer connections, we can also have your
                channel advertised across other studios.
              </StyledLineInfoBlockP>
              <StyledLineInfoBlockP $white>
                Our marketing strategies are highly effective and unique, while
                requiring zero effort or intervention from you.
              </StyledLineInfoBlockP>
              <StyledLineInfoBlockP $white>
                Stay with us for a while, and soon enough you could appear in
                many front-page games, all across Roblox.
              </StyledLineInfoBlockP>
              <StyledLineInfoBlockP $white>
                In return for this, all you have to do is promote these games to
                your audience on the rare occasion that we request it, like on
                release day. Completely optionally.
              </StyledLineInfoBlockP>
              <CtaInlineText to="/benefits/game">
                View a full breakdown of all benefits from our games
              </CtaInlineText>
            </StyledLineInfoBlock>
            <StyledBorderedP>
              You benefit from us advertising your channel, and you also benefit
              from promoting the games, as the more success these games
              experience, the more players will be directed to your Youtube
              channel.
            </StyledBorderedP>
          </StyledSbs>
        </StyledXStrip>
      </div>

      <div style={{ marginTop: "200px" }}>
        <StyledIndexHeader>
          <h2>#3</h2>
          <h2>🎮</h2>
        </StyledIndexHeader>
        <StyledHeadingH2>
          You earn a percentage of entire profits from our games
        </StyledHeadingH2>

        <StyledXStrip $push={2}>
          <StyledSbs $gap="5%" style={questionsSbsStyle}>
            <StyledLineInfoBlockH1
              $noBack
              style={{ color: "var(--contrast-one)" }}
            >
              Q:
            </StyledLineInfoBlockH1>
            <div>
              <StyledQuestion>
                Trying to make money from Youtube alone can be difficult due to
                the unreliability of the algorithm and market, as well as the
                fierce competition
              </StyledQuestion>
              <StyledQuestion>
                Why wouldn’t you take our free offer, which is to increase your
                number of income streams? And completely free and passively.
              </StyledQuestion>
            </div>
          </StyledSbs>
        </StyledXStrip>

        <StyledXStrip $pull={2}>
          <StyledSbs
            $gap="5%"
            style={Object.assign({}, answerSbsStyle, {
              paddingLeft: !queryWrap.matches && "6%",
              justifyContent: "start",
              alignItems: "start",
            })}
          >
            <StyledLineInfoBlock>
              <StyledLineInfoBlockH1
                $noBack
                style={{ color: "var(--contrast-two)" }}
              >
                A:
              </StyledLineInfoBlockH1>
              <StyledLineInfoBlockP $white>
                By agreeing to promote the games in order to be advertised
                in-game etc. You also gain a percentage share of those game’s
                total profits. (You can be involved in multiple games at once).
              </StyledLineInfoBlockP>
              <StyledLineInfoBlockP $white>
                This means that whenever a player purchases an item in game, you
                get a percentage of that purchase’s profit.
              </StyledLineInfoBlockP>
              <StyledLineInfoBlockP $white>
                Your percentage ownership of the game is calculated based on how
                much you have promoted the game as well as how many other
                youtuber clients who have also agreed to promote it just like
                you.
              </StyledLineInfoBlockP>
              <CtaInlineText to="/benefits/game">
                View a full breakdown of all benefits from our games
              </CtaInlineText>
            </StyledLineInfoBlock>
          </StyledSbs>
        </StyledXStrip>
      </div>
    </section>
  );
}

export default OurBrand;
