import { StyledFlexbox, StyledSbs } from "components/styles/SideBySide.styled";
import { StyledXStrip } from "_components/themes/brand/XStrip.styled";

const ComparisonContainer = ({
  xStyle = "push",
  title,
  description,
  children,
  ...props
}) => {
  return (
    <StyledXStrip
      $noOffset
      $push={xStyle === "push" ? 3 : undefined}
      $pull={xStyle === "pull" ? 3 : undefined}
      style={{ height: "auto" }}
    >
      <StyledSbs $column style={{ height: "auto" }}>
        <StyledFlexbox $align="center" style={{ margin: "50px 0" }}>
          <h1>{title}</h1>
          <p style={{ maxWidth: "450px", textAlign: "center" }}>
            {description}
          </p>
        </StyledFlexbox>
        <StyledSbs $width="100%" $defaultWrap $align="start" {...props}>
          {children}
        </StyledSbs>
      </StyledSbs>
    </StyledXStrip>
  );
};

export default ComparisonContainer;
