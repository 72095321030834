import styled, { css } from "styled-components";

export const ArrowIndentStyle = css<{
  $offset?: string;
  $col?: string;
  $shape?: "bullet";
}>`
  --offset: ${(props) => props.$offset || "-15px"};
  --col: ${(props) => props.$col || "var(--main-primary)"};

  margin-left: 20px;
  display: block;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;

    top: 1rem;
    transform: translate(-50%, -50%);
    left: var(--offset);

    width: 6px;
    height: 8px;
    background-color: var(--col);

    clip-path: ${(props) =>
      props.$shape === "bullet"
        ? "circle(2px)"
        : "polygon(0 0, 100% 50%, 0 100%)"};

    transition: rotate 0.1s ease-in-out;
  }
`;

// If setting --invis-col (color) in caller object, set it after calling this component (to override default)
export const DashedUnderlineStyle = css`
  --invis-col: black;
  --main-col: rgba(255, 255, 255, 100%);

  content: "";
  bottom: 0;
  height: 4px;
  width: 100%;
  display: block;
  position: absolute;
  opacity: 0.6;
  background-image: linear-gradient(
    to right,
    var(--invis-col) 30%,
    var(--main-col) 10%
  );
  background-size: 30px 1px;
  background-repeat: repeat-x;
  background-position: top;
`;

export const DashedUnderlineVerticleStyle = css`
  ${DashedUnderlineStyle}

  top: 0;
  height: 100%;
  width: 4px;
  background-image: linear-gradient(
    to bottom,
    var(--invis-col) 30%,
    var(--main-col) 10%
  );
  background-size: 1px 30px;
  background-repeat: repeat-y;
  background-position: top;
`;

export const StyledArrowIndentSpan = styled.span<{
  $offset?: string;
  $col?: string;
  $shape?: "bullet";
}>`
  ${ArrowIndentStyle}
`;

export const StyledArrowIndentP = styled.p<{
  $offset?: string;
  $col?: string;
  $shape?: "bullet";
  $indent?: boolean | string;
}>`
  --indent: ${(props) =>
    props.$indent === true
      ? "25px"
      : props.$indent != undefined
      ? props.$indent
      : "0"};

  ${ArrowIndentStyle}

  display: inline-block;
  padding-left: var(--indent);

  &::before {
    left: ${(props) =>
      props.$indent ? "calc(var(--indent) + var(--offset))" : "var(--offset)"};
  }
`;

export const StyledProIndentP = styled.p<{
  $offset?: string;
  $col?: string;
  $shape?: "bullet";
}>`
  ${ArrowIndentStyle}

  color: var(--action-primary);

  &::before {
    clip-path: polygon(
      45% 0,
      55% 0,
      55% 45%,
      100% 45%,
      100% 55%,
      55% 55%,
      55% 100%,
      45% 100%,
      45% 55%,
      0 55%,
      0 45%,
      45% 45%
    );

    height: 12px;
    width: 12px;
    background-color: var(--action-primary);
  }
`;

export const StyledConIndentP = styled.p<{
  $offset?: string;
  $col?: string;
  $shape?: "bullet";
}>`
  ${ArrowIndentStyle}

  color: var(--sub-info-light);

  &::before {
    clip-path: polygon(0 45%, 100% 45%, 100% 55%, 0 55%);

    height: 12px;
    width: 12px;
    background-color: var(--sub-info-light);
  }
`;
