import { useContext } from "react";

import {
  StyledAspectRatioContainer,
  StyledAspectRatioWrapper,
} from "../styles/AspectRatioContainer.styled";

import ContentGallery from "../objects/gallery/ContentGallery";
import { StyledContentSection } from "components/styles/Section.styled";
import FlagHeading from "components/objects/common/FlagHeading";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

import { GalleryContext } from "../contexts/GalleryContext";
import { QueryContext } from "components/contexts/QueryContext";

import edits from "../../js/data/edits";

const Edits = () => {
  const { queryWrap } = useContext(QueryContext);
  return (
    <StyledContentSection>
      <FlagHeading heading="Edits">
        These examples were made for previous <i>Partners</i>. They requested
        this exact style, which we matched exactly. <i>Staff</i> members working
        with the <i>Partners</i> at that time produced these examples.{" "}
        <LinkSpan to="/benefits/production">Learn more</LinkSpan>
      </FlagHeading>

      <StyledAspectRatioWrapper $maxHeight="1000px">
        <StyledAspectRatioContainer
          $aspectRatio={queryWrap.matches ? "1:1" : "2:1"}
          name="edits"
        >
          <GalleryContext.Provider value={edits}>
            <ContentGallery
              className="ratio-child"
              title="Edits"
              style={{ padding: "5% 0" }}
            />
          </GalleryContext.Provider>
        </StyledAspectRatioContainer>
      </StyledAspectRatioWrapper>
    </StyledContentSection>
  );
};

export default Edits;
