import React from "react";
import { ReactSession } from "react-client-session";

import { StyledSettingsPanel } from "../../styles/Settings.styled";

import PartnerSettings from "../collections/PartnerSettings";
import ProductionSettings from "../collections/ProductionSettings";

const PaymentSettings = () => {
  const userAccountType = ReactSession.get("useraccounttype");

  return (
    <StyledSettingsPanel>
      {userAccountType === "Partner" && <PartnerSettings />}
      {userAccountType === "Production" && (
        <ProductionSettings
          dbName="productioninformation"
          columnName="PayPal"
        />
      )}
      {userAccountType === "Affiliate" && (
        <ProductionSettings
          dbName="clientinformation"
          columnName="PayAccount"
        />
      )}
    </StyledSettingsPanel>
  );
};

export default PaymentSettings;
