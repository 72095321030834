import { ReactSession } from "react-client-session";

import { StyledFlexbox } from "components/styles/SideBySide.styled";
import SettingBlock from "_components/features/profile/settings/SettingBlock";
import CopyToClipboard from "_components/atoms/buttons/CopyToClipboard";

import { SITE_URL } from "js/data/constants";

const AffiliateAccountInfo = () => {
  const userId = ReactSession.get("useruid");

  return (
    <SettingBlock $height="auto" title="Affiliate Link">
      <StyledFlexbox $align="center" $justify="center" $width="100%">
        <p>Share this link!</p>
        <CopyToClipboard link={`${SITE_URL}?via=${userId}`} />
      </StyledFlexbox>
    </SettingBlock>
  );
};

export default AffiliateAccountInfo;
