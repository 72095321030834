import { useState } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledSbs } from "components/styles/SideBySide.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledXStrip } from "_components/themes/brand/XStrip.styled";
import {
  StyledLineInfoBlock,
  StyledLineInfoBlockP,
} from "_components/molecules/containers/common/styles/StyledInfoBlock.styled";

import { SectionHeading } from "_components/atoms/common/styles/SectionHeadings.styled";
import SwitchFlip from "_components/atoms/buttons/SwitchFlip";
import PackageOption from "_components/molecules/displays/PackageOption";
import ParamTabSet from "_components/function/handlers/ParamTabSet";

const PartnerPlans = () => {
  const [isRbxYoutuber, setIsRbxYoutuber] = useState(true);

  const dividers = ["Features", "Stats", "Pricing"];

  const basic = [
    [
      ["Game Profits", true],
      ["Free Advertising", isRbxYoutuber],
      ["Collaborations", true],
      ["Personal Production Team", true],
      ["Out-of-game Advertising", isRbxYoutuber],
      ["In-game Advertising", false],
      ["Access To All Special Offers", false],
      ["Access To All Site Features", false],
      ["Priority Privilege", false],
    ],
    [
      ["Minimum Percentage Ownership Per-Game", "1%"],
      ["Maximum Percentage Ownership Per-Game", "1/5"],
      ["Out-of-game Features", isRbxYoutuber ? "All" : "None"],
      ["In-game Features", isRbxYoutuber ? "None" : "None"],
      ["Maximum Games Selectable", "1"],
      ["Maximum Team Members", "2"],
      ["Revisions Entitled", "1"],
      ["Staff Skill Level", "Great"],
    ],
    [
      ["Price Per Edit", "$1.10", ["Per minute of running time"]],
      ["Price Per Thumbnail", "$5.50"],
    ],
  ];

  const premium = [
    [
      ["Game Profits", true],
      ["Free Advertising", isRbxYoutuber],
      ["Collaborations", true],
      ["Personal Production Team", true],
      ["Out-of-game Advertising", isRbxYoutuber],
      ["In-game Advertising", isRbxYoutuber],
      ["Access To All Special Offers", true],
      ["Access To All Site Features", false],
      ["Priority Privilege", false],
    ],
    [
      ["Minimum Percentage Ownership Per-Game", "1/5"],
      ["Maximum Percentage Ownership Per-Game", "2/5"],
      ["Out-of-game Features", isRbxYoutuber ? "All" : "None"],
      ["In-game Features", isRbxYoutuber ? "Minor" : "None"],
      ["Maximum Games Selectable", "2"],
      ["Maximum Team Members", "3"],
      ["Revisions Entitled", "2"],
      ["Staff Skill Level", "Incredible"],
    ],
    [
      ["Price Per Edit", "$1.90", ["Per minute of running time"]],
      ["Price Per Thumbnail", "$7.50"],
    ],
  ];

  const pro = [
    [
      ["Game Profits", true],
      ["Free Advertising", isRbxYoutuber],
      ["Collaborations", true],
      ["Personal Production Team", true],
      ["Out-of-game Advertising", isRbxYoutuber],
      ["In-game Advertising", isRbxYoutuber],
      ["Access To All Special Offers", true],
      ["Access To All Site Features", true],
      ["Priority Privilege", true],
    ],
    [
      ["Minimum Percentage Ownership Per-Game", "2/5"],
      ["Maximum Percentage Ownership Per-Game", "3/5"],
      ["Out-of-game Features", isRbxYoutuber ? "All" : "None"],
      ["In-game Features", isRbxYoutuber ? "Major" : "None"],
      ["Maximum Games Selectable", "3"],
      ["Maximum Team Members", "4"],
      ["Revisions Entitled", "3"],
      ["Staff Skill Level", "Exceptional"],
    ],
    [
      ["Price Per Edit", "$2.90", ["Per minute of running time"]],
      ["Price Per Thumbnail", "$10.00"],
    ],
  ];

  return (
    <StyledDualXStrip $pad>
      <ParamTabSet
        setTab={setIsRbxYoutuber}
        query="rbx"
        parser={(value) => value !== "no"}
      />

      <SectionHeading
        title="Partner Plans"
        description="View the tiered package options you can change between as a Partner in our company"
      />

      <StyledStandardSpacer $paddingTop="50px" />
      <SwitchFlip
        $text="Toggle to 'Yes' if your content consists primarily of Roblox content. There is a very slight difference between the two. Click to learn more."
        to="/youtuber"
        enabled={isRbxYoutuber}
        setEnabled={setIsRbxYoutuber}
      >
        Roblox Youtuber
      </SwitchFlip>

      <StyledStandardSpacer $paddingTop="50px" />

      <StyledSbs $align="start" $gap="min(50px, 5%)">
        <PackageOption
          title="Basic"
          description="Budget Friendly"
          dividers={dividers}
          entries={basic}
          to="/join?package=basic"
          alt
        />
        <PackageOption
          $highlighted
          title="Premium"
          description="Most Popular"
          dividers={dividers}
          entries={premium}
          to="/join?package=premium"
        />
        <PackageOption
          title="Pro"
          description="Best Value"
          dividers={dividers}
          entries={pro}
          to="/join?package=pro"
          alt
        />
      </StyledSbs>

      <StyledXStrip $splitter />

      <StyledCenteredArea>
        <StyledLineInfoBlock
          $colBack="var(--main-background)"
          $addedHeight="50px"
          $maxWidth="min(700px, 80%)"
        >
          <h1 style={{ margin: "0 0 30px 0" }}>Overview</h1>
          <h2 style={{ margin: "0" }}>Basic</h2>
          <StyledLineInfoBlockP>
            If you are on a budget or just trying out our services, then this
            option is convenient to being with! You receive a very qualified
            team of talented video editors and thumbnail artists from our
            company as the members of your personal production team. You get to
            experience the benefits of partnership with the game you select to
            be involved with, but just at a more casual level.
          </StyledLineInfoBlockP>
          <h2 style={{ margin: "0" }}>Premium</h2>
          <StyledLineInfoBlockP>
            A great middle-ground between price and value. You get higher
            qualified, skilled video editors and thumbnail artists from our
            company as the members of your personal production team. You get to
            see a rise in opportunity across our company with higher scale of
            benefits received from game partnerships, which you can now select
            multiple of, simultaneously. Your brand will see considerable spikes
            in growth due to a whole new source of income and promotion opened
            up to you at a larger scale.
          </StyledLineInfoBlockP>
          <h2 style={{ margin: "0" }}>Pro</h2>
          <StyledLineInfoBlockP>
            You are first to receive all new features, rewards, information and
            opportunities. You receive the highest qualified, skilled video
            editors and thumbnail artists from our company as the members of
            your personal production team. You are flagged as "Priority
            Privileged", meaning that you can perform "overriding" actions on
            YouTubers on a lower tiered package plan, making sure you always get
            what you desire without competing with other Partners for it. You
            become a fully developed Partner with us, and your access to the
            assets we can offer become as limitless as you could possibly need.
            Game partnerships visually become main sources of income and
            promotion for your brand due to the sheer quantity and quality of
            your access to their benefits.
          </StyledLineInfoBlockP>
        </StyledLineInfoBlock>
      </StyledCenteredArea>
    </StyledDualXStrip>
  );
};

export default PartnerPlans;
