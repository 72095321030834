import React, { useContext } from "react";
import { StyledSbs } from "../styles/SideBySide.styled";
import {
  StyledLineInfoBlock,
  StyledLineInfoBlockH1,
  StyledLineInfoBlockP,
} from "../../_components/molecules/containers/common/styles/StyledInfoBlock.styled";
import {
  StyledAspectRatioContainer,
  StyledAspectRatioWrapper,
} from "../styles/AspectRatioContainer.styled";

import { QueryContext } from "../contexts/QueryContext";

import RotCircle from "../../_components/molecules/displays/circle/RotCircle";

import { clientInfoList } from "js/data/arrays/rotCircleInfoLists";

const OurServicesSbs = (props) => {
  const { queryWrap } = useContext(QueryContext);

  return (
    <StyledSbs $yGap="200px" {...props}>
      <StyledLineInfoBlock
        $colBack="var(--main-primary)"
        $addedHeight={queryWrap.matches ? "50%" : "200%"}
        style={{ maxWidth: "590px" }}
      >
        <StyledLineInfoBlockH1 $push>
          Solving the problems Roblox Youtubers face
        </StyledLineInfoBlockH1>
        <StyledLineInfoBlockP $maxWidth="450px" style={{ margin: "3px 0" }}>
          Every individual youtuber we work with, we give full attention and
          support to. Our aim is for you to grow your channel. How would we
          expect to win if our clients lose?
        </StyledLineInfoBlockP>
      </StyledLineInfoBlock>

      <StyledAspectRatioWrapper
        $centered
        $maxHeight="600px"
        $maxWidth="600px"
        $maintainChildPos
      >
        <StyledAspectRatioContainer
          $aspectRatio={queryWrap.matches ? "1:1" : null}
          // Usual radius of StyledRotCircle which we recreate with this parent container
          $height="450px"
          $width={queryWrap.matches ? "100%" : "450px"}
          $childScale={queryWrap.matches ? "80%" : "100%"}
          $childCentered
        >
          <RotCircle infoList={clientInfoList} className="ratio-child" />
        </StyledAspectRatioContainer>
      </StyledAspectRatioWrapper>
    </StyledSbs>
  );
};

export default OurServicesSbs;
