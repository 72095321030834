import { ReactSession } from "react-client-session";

import { StyledSettingsPanel } from "_components/features/profile/styles/Settings.styled";
import SettingBlock from "_components/features/profile/settings/SettingBlock";
import SettingItem from "_components/features/profile/settings/SettingItem";

import ClientAccountInfo from "../collections/ClientAccountInfo";
import AffiliateAccountInfo from "../collections/AffiliateAccountInfo";
import ProductionAccountInfo from "../collections/ProductionAccountInfo";

const AccountSettings = () => {
  const userAccountType = ReactSession.get("useraccounttype");

  return (
    <StyledSettingsPanel>
      {userAccountType === "Affiliate" && <AffiliateAccountInfo />}
      <SettingBlock title="Account Info">
        <SettingItem title="Username" value={ReactSession.get("useruid")} />
        <SettingItem
          title="Password"
          value={ReactSession.get("userpwd")}
          dbName="users"
          columnName="PasswordHash"
        />
        <SettingItem title="Email" value={ReactSession.get("useremail")} />
        <SettingItem title="Account Type" value={userAccountType} />

        {(userAccountType === "Partner" || userAccountType === "Affiliate") && (
          <ClientAccountInfo />
        )}
        {userAccountType === "Production" && <ProductionAccountInfo />}
      </SettingBlock>
    </StyledSettingsPanel>
  );
};

export default AccountSettings;
