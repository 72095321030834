import React from "react";
import { useNavigate } from "react-router-dom";

interface LinkedProps {
  [x: string]: any;
  to?: string;
}

function linked(Component: React.ComponentType<any>) {
  const LinkedComponent = ({ to, children, ...props }: LinkedProps) => {
    const navigate = useNavigate();
    return (
      <Component
        {...props}
        $noPointer={to == undefined}
        onClick={to ? () => navigate(to) : undefined}
      >
        {children}
      </Component>
    );
  };
  return LinkedComponent;
}

export default linked;
