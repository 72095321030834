import { useState, useRef, useEffect } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import {
  StyledFormContainer,
  StyledFormTitle,
} from "_components/features/form/styles/FormStructs.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";

import FormCta from "_components/features/form/FormCta";
import FormItem from "_components/features/form/FormItem";
import FormSelect from "_components/features/form/elements/FormSelect";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const topicsList = [
  "General",
  "Bug Report",
  "Complaint",
  "Feature Request",
  "Account Issue",
  "Confusion",
];

const ContactForm = () => {
  const formRef = useRef(null);
  const [selectedTopic, setSelectedTopic] = useState("");

  const userEmail = ReactSession.get("useremail");

  const handleSubmit = async (e) => {
    e.preventDefault();

    getAccessToken()
      .then((token) =>
        axios.post(
          `${ENDPOINT}/api/comm/contact`,
          {
            topic: selectedTopic,
            body: formRef.current.message.value,
            email: formRef.current.email.value,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
      )
      .finally(() => {
        formRef.current.reset();
        formRef.current.email.value = userEmail;
      });
  };

  return (
    <StyledDualXStrip $pad>
      <StyledCenteredArea>
        <StyledFormContainer
          $bg="light"
          $shadowed
          ref={formRef}
          onSubmit={handleSubmit}
          style={{
            width: "min(70%, 700px)",
            borderRadius: "5px",
          }}
        >
          <StyledFormTitle $bottom="10px">Contact Form</StyledFormTitle>

          <FormItem
            $label="Email"
            placeholder="example@gmail.com"
            type="email"
            name="email"
            initialValue={userEmail}
            required
            autoFocus
          />
          <FormSelect
            setSelection={setSelectedTopic}
            optionsList={topicsList}
            placeholder="-- SELECT TOPIC --"
            style={{ gridColumn: "1/-1" }}
          />
          <FormItem
            $label="Message"
            $large={true}
            placeholder="Message to us about your chosen topic (max 400 characters)"
            type="text"
            name="message"
            minLength={25}
            maxLength={400}
            required
          />

          <FormCta>Confirm & Send</FormCta>
        </StyledFormContainer>
      </StyledCenteredArea>
    </StyledDualXStrip>
  );
};

export default ContactForm;
