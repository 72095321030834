import React, { useRef } from "react";
import axios from "axios";

import { StyledSbs } from "components/styles/SideBySide.styled";
import {
  StyledFormContainer,
  StyledFormTitle,
} from "_components/features/form/styles/FormStructs.styled";
import { StyledDualXStrip } from "_components/themes/brand/DualXStrip.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import {
  SectionHeading,
  StyledSectionH2,
} from "_components/atoms/common/styles/SectionHeadings.styled";
import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";

import FormCta from "_components/features/form/FormCta";
import FormItem from "_components/features/form/FormItem";

import { ENDPOINT } from "js/data/constants";
import getAccessToken from "js/features/auth/getAccessToken";

const TimeOff = () => {
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(
        `${ENDPOINT}/api/payment/fulfillment/time`,
        {
          reason: formRef.current.reason.value,
          duration: formRef.current.duration.value,
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then(() => formRef.current.reset())
      .catch(console.error);
  };

  return (
    <StyledDualXStrip $pad>
      <SectionHeading
        title="Time Off"
        description="Request temporary time off from all work"
      />

      <StyledStandardSpacer $paddingTop="50px" />

      <StyledCenteredArea>
        <StyledFormContainer
          $bg="light"
          $shadowed
          ref={formRef}
          onSubmit={handleSubmit}
          style={{
            width: "min(70%, 700px)",
            borderRadius: "5px",
          }}
        >
          <StyledFormTitle $bottom="10px">
            Time Off Request Form
          </StyledFormTitle>

          <FormItem
            $label="Duration"
            placeholder="2-14 (days)"
            type="number"
            name="duration"
            max={14}
            min={2}
            required
            autoFocus
            style={{ gridColumn: "1 / 2" }}
          />
          <FormItem
            $label="Reason"
            $large={true}
            placeholder="Explain reason for needing this time off (max 200 characters)"
            type="text"
            name="reason"
            maxLength={200}
            required
          />

          <FormCta>Confirm & Send</FormCta>
        </StyledFormContainer>
      </StyledCenteredArea>
    </StyledDualXStrip>
  );
};

export default TimeOff;
