import React, { useState } from "react";

import { StyledTransparentBackground } from "../styles/Background.styled";
import { StyledContentSection } from "../styles/Section.styled";
import { StyledGamesContainer } from "_components/features/game/styles/Card.styled";

import GameCard from "_components/features/game/GameCard";
import FlagHeading from "../objects/common/FlagHeading";

import { GalleryContext } from "../contexts/GalleryContext";

import gameDatas from "../../js/data/gameDatas";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const Games = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(-1);

  return (
    <StyledContentSection
      name="games"
      style={{ height: "auto", marginBottom: "500px" }}
    >
      <FlagHeading heading="Games">
        These are games which are currently available for you to gain profits
        and advertising from. New studios and games are regularly created by us.
        We regularly partner also with already successful studios and games,
        which all appear here.{" "}
        <LinkSpan to="/benefits/game">Learn more</LinkSpan>
      </FlagHeading>
      <StyledGamesContainer>
        {gameDatas.map((data, index) => (
          <GalleryContext.Provider key={index} value={data.preview}>
            <GameCard
              data={data}
              index={index}
              activeCardIndex={activeCardIndex}
              setActiveCardIndex={setActiveCardIndex}
            />
          </GalleryContext.Provider>
        ))}
      </StyledGamesContainer>
    </StyledContentSection>
  );
};

export default Games;
