import styled from "styled-components";

export const StyledThinInfoPanel = styled.div`
  position: relative;
  overflow: hidden;

  height: 100%;
  width: min(600px, 80%);
`;

export const StyledPanelHeading = styled.div<{ $highlighted?: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  background-color: ${(props) =>
    props.$highlighted
      ? "var(--action-primary)"
      : "var(--main-secondary-dark)"};

  border-radius: 10px 10px 0 0;
  padding: 30px 0;

  h2 {
    margin: 0;
    text-align: center;
  }

  p {
    margin: 0;
    color: white;
    text-align: center;
  }
`;

export const StyledPanelContainer = styled.div<{
  $highlighted?: boolean;
  $padding?: string;
}>`
  position: relative;

  background-color: var(--main-background);
  border-radius: 0 0 10px 10px;
  padding: ${(props) => props.$padding || "30px min(70px, 20%)"};

  border: ${(props) =>
    props.$highlighted ? "2px solid var(--action-primary)" : "unset"};

  p {
    margin: 0;
    margin-bottom: 12px;
  }
`;
