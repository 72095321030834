import React from "react";

import Gallery from "./Gallery";
import { StyledTransparentBackground } from "../../styles/Background.styled";

function ContentGallery(props) {
  return (
    <>
      <Gallery {...props} />
      <StyledTransparentBackground height="100%" />
    </>
  );
}

export default ContentGallery;
