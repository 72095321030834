import React, { useRef, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledFormOptions,
  StyledFormP,
  StyledGroupWrapper,
} from "../styles/FormStructs.styled";

import FormSelect from "../elements/FormSelect";
import FormErrorMessage from "../elements/FormErrorMessage";
import FormOption from "../FormOption";
import FormItem from "../FormItem";
import FormCta from "../FormCta";

import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import ActiveUploads from "_components/features/file/upload/ActiveUploads";

import LoadingStatus from "_components/atoms/info/LoadingStatus";

import { UploadContext } from "components/contexts/UploadContext";

import { ENDPOINT } from "js/data/constants";
import getUploadID from "js/getUploadID";
import getAccessToken from "js/features/auth/getAccessToken";
import formErrorMessages, { getErrorMessage } from "js/data/formErrorMessages";

const ProductionForm = ({ setFormVisible }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [
    [progressUris, setProgressUris],
    [fileDatas, setFileDatas],
    [completedUploads, setCompletedUploads],
    folderName,
  ] = useContext(UploadContext);
  const [areFilesUploaded, setAreFilesUploaded] = useState(false);

  const [canSendForm, setCanSendForm] = useState(true);
  const [canUpload, setCanUpload] = useState(true);

  const [editsEnabled, setEditsEnabled] = useState(false);
  const [thumbnailsEnabled, setThumbnailsEnabled] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);

  const [selectedDuration, setSelectedDuration] = useState("");
  const [availableDurations, setAvailableDurations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  const userId = ReactSession.get("userid");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!canSendForm) {
      return;
    }

    const formData = {
      videoName: formRef.current.name.value,
      duration: selectedDuration,
      editDescription: formRef.current?.eDesc?.value,
      thumbnailDescription: formRef.current?.tDesc?.value,
      editsEnabled,
      thumbnailsEnabled,
    };

    const fileInfo = Object.values(fileDatas).map((file) => {
      let fileUri;
      for (const [uri, file_] of Object.entries(fileDatas)) {
        if (file_.name === file.name) {
          fileUri = uri;
        }
      }
      return {
        name: file.name,
        type: file.type,
        uri: getUploadID(fileUri),
      };
    });

    setCanSendForm(false);

    axios
      .post(
        `${ENDPOINT}/api/comm/send-production-form`,
        {
          folderName,
          form: formData,
          file: fileInfo,
        },
        {
          headers: {
            Authorization: await getAccessToken(),
          },
        }
      )
      .then((res) => {
        setProgressUris([]);
        setFileDatas({});
        setCompletedUploads([]);
        setFormVisible(false);
        navigate("/file?tab_outgoing=1");
      })
      .catch((error) => {
        let status = error?.response?.data?.status;
        if (status) {
          setErrorMessages([getErrorMessage(status)]);
        }
        console.error("Failed to send prod form.", error);
        setCanSendForm(true);
      });
  };

  const isFileTypeUploaded = (targetFileType) => {
    const uploadedFilesOfType = Object.entries(fileDatas).filter(
      ([uri, file]) => {
        const fileType = file.type.split("/")[0];
        return (
          fileType === targetFileType &&
          completedUploads.find((targetUri) => targetUri === uri) != undefined
        );
      }
    );
    return uploadedFilesOfType.length > 0;
  };

  const fetchAvailableDurations = async () => {
    setIsLoading(true);
    axios
      .get(`${ENDPOINT}/api/payment/transactions?returns=rows`, {
        headers: {
          Authorization: await getAccessToken(),
        },
      })
      .then((res) => {
        const durations = [];
        res.data.edits.forEach((data) => {
          if (!durations.find((duration) => duration == data.Duration)) {
            durations.push(data.Duration);
          }
        });
        setAvailableDurations(durations);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  useEffect(
    () => setAreFilesUploaded(isFileTypeUploaded("video")),
    [completedUploads]
  );

  useEffect(() => {
    fetchAvailableDurations();

    // Resets query params to prevent auto-enable of form on switching back to tab
    const queryParams = new URLSearchParams(search);
    const formDefault = queryParams.get("form_default");
    if (formDefault) {
      if (formDefault === "edits") {
        setEditsEnabled(true);
        setThumbnailsEnabled(false);
      } else if (formDefault === "thumbnails") {
        setEditsEnabled(false);
        setThumbnailsEnabled(true);
      }
    }
    navigate("/file");
  }, []);

  const handleOptionDeselection = (fileType, value) => {
    const isVideo = fileType === "video";
    const setEnabled = isVideo ? setEditsEnabled : setThumbnailsEnabled;
    const errMessage = `Delete all uploaded ${
      isVideo ? "videos" : "images"
    } before de-selecting 'Video ${isVideo ? "Edit" : "Thumbnail"}' option`;

    if (value === false && isFileTypeUploaded(fileType)) {
      if (!errorMessages.find((msg) => msg === errMessage)) {
        setErrorMessages([...errorMessages, errMessage]);
      }
      return;
    }
    setErrorMessages(errorMessages.filter((msg) => msg !== errMessage));
    setEnabled(value);
  };

  return (
    <StyledCenteredArea
      style={{
        backgroundColor: "var(--main-secondary)",
        width: "95%",
        borderRadius: "5px",
      }}
    >
      <StyledFormContainer
        ref={formRef}
        onSubmit={handleSubmit}
        style={{ width: "70%", borderRadius: "5px", minWidth: "500px" }}
      >
        <StyledFormTitle $bottom="10px">Service Request Form</StyledFormTitle>

        <StyledGroupWrapper>
          <StyledFormP>
            Select the service(s) you wish to receive from your production team
          </StyledFormP>

          <StyledFormOptions>
            <FormOption
              value={!editsEnabled}
              name="eEnabled"
              inputType="checkbox"
              selectedOption={editsEnabled}
              setSelectedOption={(value) =>
                handleOptionDeselection("video", value)
              }
            >
              Video Edit
            </FormOption>
            <FormOption
              value={!thumbnailsEnabled}
              name="tEnabled"
              inputType="checkbox"
              selectedOption={thumbnailsEnabled}
              setSelectedOption={(value) =>
                handleOptionDeselection("image", value)
              }
            >
              Video Thumbnail
            </FormOption>
          </StyledFormOptions>
        </StyledGroupWrapper>

        {(editsEnabled || thumbnailsEnabled) && (
          <FormItem
            $label="Video Name"
            placeholder="Name of finished file you receive back (max 50 characters)"
            type="text"
            name="name"
            maxLength={50}
            required
            autoFocus
          />
        )}

        {editsEnabled && (
          <>
            <LoadingStatus
              status={isLoading ? 0 : availableDurations.length > 0}
              style={{ gridColumn: "1 / -1", textAlign: "center" }}
            />
            <FormSelect
              setSelection={setSelectedDuration}
              optionsList={availableDurations}
              placeholder="-- SELECT DURATION (mins) --"
              style={{ gridColumn: "1 / -1" }}
            />
            <FormItem
              $label="Video Edit's Description"
              $large={true}
              placeholder="Let editors know any specific requirements or extra details for creating the edit (max 400 characters)"
              type="text"
              name="eDesc"
              maxLength={400}
              required
            />
          </>
        )}

        {thumbnailsEnabled && (
          <FormItem
            $label="Video Thumbnail's Description"
            $large={true}
            placeholder="Let artist know how thumbnail should look, theme, characters, context of video it's connected to (max 400 characters)"
            type="text"
            name="tDesc"
            maxLength={400}
            required
          />
        )}

        {(editsEnabled || thumbnailsEnabled) && (
          <>
            <StyledGroupWrapper>
              <StyledFormP>
                {editsEnabled && (
                  <>
                    <span
                      style={{
                        color: "var(--action-primary)",
                        fontWeight: "bold",
                      }}
                    >
                      [Required]
                    </span>{" "}
                    Upload your raw video footage to be edited into a video
                  </>
                )}
                <br />
                {thumbnailsEnabled && (
                  <>
                    <span
                      style={{
                        color: "var(--action-primary)",
                        fontWeight: "bold",
                      }}
                    >
                      [Optional]
                    </span>{" "}
                    Upload your thumbnail sketches/design-guides to help the
                    artists
                  </>
                )}
              </StyledFormP>
              <StyledFormP>
                <span
                  style={{
                    color: "var(--exit-main)",
                    fontWeight: "bold",
                  }}
                >
                  [Bug]
                </span>{" "}
                If the upload button disappears, that means that your content IS
                uploading! The visual feedback for upload progress is actively
                being fixed but it is currently not appearing. We will fix it
                ASAP. Sorry!
              </StyledFormP>
              <ActiveUploads
                setCanUpload={setCanUpload}
                canUpload={canUpload}
                directory={`${userId}/sent/raw-footage/${folderName}`}
                acceptTypes={
                  "" +
                  (editsEnabled ? "video/*" : "") +
                  (editsEnabled && thumbnailsEnabled ? ", " : "") +
                  (thumbnailsEnabled ? "image/*" : "")
                }
              />
            </StyledGroupWrapper>

            {errorMessages.length > 0 && (
              <StyledGroupWrapper>
                {errorMessages.map((message, index) => (
                  <FormErrorMessage message={message} key={index} />
                ))}
              </StyledGroupWrapper>
            )}

            {((editsEnabled && areFilesUploaded) || !editsEnabled) && (
              <FormCta>Confirm & Send</FormCta>
            )}
          </>
        )}
      </StyledFormContainer>
    </StyledCenteredArea>
  );
};

export default ProductionForm;
